type Props = {
    state: string,
    setState: React.Dispatch<React.SetStateAction<string>>,
    placeholder?: string
    className?: string
    readOnly?: boolean
}

export const Textarea = (({ placeholder, setState, state, className, readOnly = false }: Props) => {
    return (
        <textarea className={`w-full p-1 border bg-white dark:bg-neutral-900
        border-black dark:border-neutral-50 
        border-opacity-25 dark:border-opacity-25 ${className}`}
            onChange={(e) => setState(e.target.value)}
            value={state}
            placeholder={placeholder}
            readOnly={readOnly}
        />
    )
})