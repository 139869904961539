import { MobileCard } from './MobileCard'
import { mobileProjects } from '../projectsData'

export const MobileCards = () => {
    return (<>
        {
            mobileProjects.map((obj: any, i: number) => {
                return (
                    <MobileCard
                        key={obj.key}
                        appIndex={i} type="games" title={obj.title}
                        description={obj.description}
                        icon={obj.icon}
                        previews={obj?.previews}
                        esrb={obj.esrb}
                        link={obj.link}
                    />
                )
            })
        }
    </>
    )
}
