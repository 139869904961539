import { useState } from 'react';
import google from '../../../assets/images/google-play-badge.png'
import everyone from '../../../assets/images/esrb/everyone.png'
import everyone10 from '../../../assets/images/esrb/everyone_10.png'
import { LazyImage } from '../../../common/LazyImage';
import { Card } from '../../../common/styled/Card';

export const MobileCard = ({ title, icon, previews, description, esrb, link }: any) => {
  const [slideCount, setSlideCount] = useState(0);

  function slideChange(slide: any) {
    if (previews?.length - 1 < slide) slide = 0;
    else if (slide < 0) slide = previews.length - 1;
    setSlideCount(slide);
  }

  let rating;
  if (esrb === "everyone") {
    rating = everyone;
  } else {
    rating = everyone10;
  }

  const dots = previews?.map((preview: any, staticIndex: any) =>
    <button key={staticIndex} className="py-2 px-2 focus:outline-none" onClick={(e) => slideChange(staticIndex)}>
      <svg fill={staticIndex === slideCount ? "red" : "white"} height="10" width="10">
        <circle cx="5" cy="5" r="4" stroke="black" strokeWidth="1px" />
      </svg>
    </button>
  );

  const arrows = previews?.length > 1 && <>
    <button className="absolute left-0 opacity-50 hover:opacity-100 text-white p-0 pl-0 focus:outline-none" onClick={(e) => slideChange(slideCount - 1)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="#666" strokeWidth="0.5px" className="fill-current" width="36px" height="36px">
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    </button>
    <button className="absolute right-0 opacity-75 hover:opacity-100 text-white p-0 pr-0 focus:outline-none" onClick={(e) => slideChange(slideCount + 1)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="#666" strokeWidth="0.5px" className="fill-current" width="36px" height="36px">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </button>
  </>

  return (
    <Card className="h-auto mt-14 md:w-2/3 mx-auto" children={
      <div className="flex">
        <div className="flex-row justify-center w-auto md:w-1/2">
          <LazyImage className={"mx-auto md:mx-0 md:float-left md:mr-2 h-16 w-16 md:h-auto md:w-auto"} src={icon} alt="Icon" />
          <h1 className="text-center md:text-left font-bold text-xl">{title}</h1>
          <p className="flex-wrap text-center md:text-left pr-4"> {description}</p>
          {link !== null &&
            <div className="flex justify-center md:justify-start gap-2 mt-8 items-center">
              <a href={link} target="_blank" rel="noopener noreferrer"><LazyImage src={google} className="w-32 mr-4" alt="Google Play"></LazyImage></a>
              <LazyImage src={rating} className="w-6" alt="esrb"></LazyImage>
            </div>
          }
        </div>
        <div className="w-0 md:w-1/2 self-center hidden md:block">
          <div className="relative flex items-center w-full h-full">
            {arrows}
            <LazyImage className='' src={previews ? previews[slideCount] : ""} alt="preview" />
          </div>
          {previews?.length > 1 && <div className="flex justify-center pt-2">{dots}</div>}
        </div>
      </div>} />
  );
}