import { useUser } from "../../contexts/UserContext"
import { Avatar } from '../Avatar';
import { useAuth } from "../../contexts/AuthContext"
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Button } from "../styled/Button";
import { Divider } from "../styled/Divider";
import { ModalCard } from "../styled/decorators/ModalCard";


type Props = {
    closeModal: () => void
}

export const ProfileOptions = ({ closeModal }: Props) => {
    const { displayName } = useUser()
    const { currentUser, logOut: logout } = useAuth()
    let location: any = useLocation()
    let from = location.state?.from || "/"

    const navigate = useNavigate()

    const handleAccount = useCallback(() => {
        closeModal()
        navigate("account", { state: { from: from } })
    }, [closeModal, from, navigate])

    const handleLogout = useCallback(() => {
        closeModal()
        logout()
    }, [logout, closeModal])

    return (
        <>
            <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 md:opacity-0 z-10" />
            <div onClick={closeModal} className="fixed top-0 left-0 flex justify-center items-center w-full h-full z-20">
                <ModalCard className="md:absolute top-0 right-0 md:w-72 md:mt-20 md:mr-2 md:py-6 gap-0"
                    children={<>
                        <div className="flex flex-col gap-2 items-center text-center">
                            <Avatar className="h-20 w-20 border border-black rounded-full" />
                            <p>{displayName}</p>
                            <p>{currentUser?.email}</p>
                        </div>
                        <Divider />
                        <div>
                            <Button text="Account Preferences" onClick={handleAccount} />
                        </div>
                        <Divider />
                        <div>
                            <Button text="Log out" onClick={handleLogout} />
                        </div>
                    </>} />
            </div>
        </>
    )
}