import {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useBooking} from "../context/BookingContext";
import CalendarView from "../components/CalendarView";
import dayjs from "dayjs";

type FlightType = {
    guest: string,
    hotel: string,
    extras: string,
    bookingNo: string,
}

export default function Hotel() {
    const {state: {extras, hotels, names, }} = useBooking();
    const {register, handleSubmit, setValue} = useForm<FlightType>({
        defaultValues: {
            guest: "",
            hotel: "",
            extras: "",
        }
    });

    const [copy, setCopy] = useState(false);
    const [textToCopy, setTextToCopy] = useState<string>('');
    const copyButtonRef = useRef<HTMLButtonElement>(null);
    const [isCalendarOpened, setIsCalendarOpened] = useState(false);
    const [checkInIsActive, setCheckInIsActive] = useState(true);
    const [selectedCheckInDate, setSelectedCheckInDate] = useState<Date | undefined>(undefined);
    const [selectedCheckOutDate, setSelectedCheckOutDate] = useState<Date | undefined>(undefined);
    const [numOfNights, setNumOfNights] = useState<number | undefined>();
    const calendarMenuRef = useRef<HTMLDivElement>(null);

    const onSubmit = handleSubmit(async (data) => {
        if (!selectedCheckInDate || !selectedCheckOutDate) return;
        let bookingDates
        if (selectedCheckInDate.getMonth() === selectedCheckOutDate.getMonth() && selectedCheckInDate.getFullYear() === selectedCheckOutDate.getFullYear()) {
            bookingDates = `${dayjs(selectedCheckInDate).format('DD')} till ${dayjs(selectedCheckOutDate).format('DD MMMM YYYY')}`
        } else if (selectedCheckInDate.getFullYear() === selectedCheckOutDate.getFullYear()) {
            bookingDates = `${dayjs(selectedCheckInDate).format('DD MMMM')} till ${dayjs(selectedCheckOutDate).format('DD MMMM YYYY')}`
        } else {
            bookingDates = `${dayjs(selectedCheckInDate).format('DD MMMM YYYY')} - ${dayjs(selectedCheckOutDate).format('DD MMMM YYYY')}`
        }
        setTextToCopy(`${data.hotel} for Guest - ${capitalizeWords(data.guest)} from ${bookingDates}, ${data.extras && `${data.extras}, `}${data.bookingNo}`)
    });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    useEffect(() => {
        function handleMouseDown(mousedownEvent: MouseEvent) {
            if (calendarMenuRef.current && !calendarMenuRef.current.contains(mousedownEvent?.target as Node)) {
                setIsCalendarOpened(false);
            }
        }
        //add mousedown listener to close calendar menu
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        }
    }, [calendarMenuRef])

    useEffect(() => {
        async function wait() {
            await new Promise(() => {
                setTimeout(() => {
                    setCopy(false);
                }, 500)
            })
        }
        if (copy) {
            wait();
        }
    }, [copy])

    function handleCopy() {
        navigator.clipboard.writeText(textToCopy)
        setCopy(true);
    }

    function capitalizeWords(str: string): string {
        return str.toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
    }

    useEffect(() => {
        if (selectedCheckInDate && selectedCheckOutDate) {
            const diffTime = Math.abs(selectedCheckOutDate.getTime() - selectedCheckInDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setNumOfNights(diffDays);
        }
    }, [selectedCheckInDate, selectedCheckOutDate])

    function handleCheckOutButton() {
        setIsCalendarOpened(true);
        setCheckInIsActive(false);
    }

    function handleCheckInButton() {
        setIsCalendarOpened(true);
        setCheckInIsActive(true);
    }

    return (
        <div className="flex flex-col gap-2 place-content-center w-full h-fit dark:text-neutral-50 ">
            <form onSubmit={(e) => onSubmit(e)} className={`flex flex-col md:gap-2  border border-black bg-neutral-200 dark:bg-neutral-800 border-opacity-50 dark:border-opacity-30 dark:border-neutral-50 shadow-md rounded p-2 w-full`}>
                <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
                    <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Hotel</span>
                    <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2 grow dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("hotel")} />
                    <select disabled={hotels.length === 0} className="dark:bg-neutral-700  px-2 cursor-pointer w text-sm " onChange={(e) => {setValue("hotel", e.target.value)}}>
                        <option value="">Select</option>
                        {hotels.map((hotel, i) => {
                            return (<option key={`${hotel}_${i}`} value={hotel}>{hotel}</option>)
                        })}
                    </select>
                </label>
                <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
                    <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Guest</span>
                    <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2 grow dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("guest")} />
                    <select disabled={names.length === 0} className="dark:bg-neutral-700  px-2 cursor-pointer w text-sm " onChange={(e) => {setValue("guest", e.target.value)}}>
                        <option value="">Select</option>
                        {names.map((name, i) => {
                            return (<option key={`${name}_${i}`} value={name}>{name}</option>)
                        })}
                    </select>
                </label>
                <div className="relative flex w-full justify-between">
                    <div className="flex flex-col">
                        <div className="">Booking Dates</div>
                        <span className="">{selectedCheckInDate && selectedCheckOutDate ? `${numOfNights} night${numOfNights && numOfNights > 1 ? "s" : ""}` : "Choose booking dates"}</span>

                    </div>
                    {
                        selectedCheckInDate || selectedCheckOutDate ?
                            <div className="flex justify-evenly items-center bg-white dark:bg-neutral-800 border dark:border-opacity-30 dark:border-neutral-50 rounded h-fit w-fit">
                                <button onClick={handleCheckInButton} className={`flex flex-col rounded p-2 dark:hover:bg-neutral-700 hover:bg-neutral-200`}>
                                    <h1 className="flex items-end text-sm font-bold">Check-in</h1>
                                    <span>{selectedCheckInDate ? dayjs(selectedCheckInDate).format('YYYY-MM-DD') : "YYYY-MM-DD"}</span>
                                </button>
                                <button onClick={handleCheckOutButton} className={`flex flex-col rounded p-2 dark:hover:bg-neutral-700 hover:bg-neutral-200`}>
                                    <h1 className="flex items-end text-sm font-bold">Check-out</h1>
                                    <span>{selectedCheckOutDate ? dayjs(selectedCheckOutDate).format('YYYY-MM-DD') : "YYYY-MM-DD"}</span>
                                </button>
                            </div> :
                            <button onClick={() => setIsCalendarOpened(true)} className="flex justify-evenly items-center bg-white dark:bg-neutral-800 border dark:border-opacity-30 dark:border-neutral-50 rounded h-14 w-48 mr-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 -960 960 960">
                                    <path d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Zm300 230q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" /></svg>
                            </button>
                    }
                    {isCalendarOpened &&
                        <div className="absolute" ref={calendarMenuRef} >
                            <CalendarView checkInIsActive={checkInIsActive} setCheckInIsActive={setCheckInIsActive}
                                selectedCheckInDate={selectedCheckInDate} selectedCheckOutDate={selectedCheckOutDate}
                                setSelectedCheckInDate={setSelectedCheckInDate} setSelectedCheckOutDate={setSelectedCheckOutDate}
                                numOfNights={numOfNights}
                                setIsCalendarOpened={setIsCalendarOpened}
                            />
                        </div>
                    }
                </div>
                <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
                    <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Extras</span>
                    <input onKeyDown={(e) => handleKeyDown(e)} className={`flex justify-stretch border rounded pl-2 dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("extras")} />
                    <select disabled={extras?.length === 0} className="dark:bg-neutral-700 bg-neutral-100 px-2 cursor-pointer w-24 text-sm" onChange={(e) => {setValue("extras", e.target.value)}}>
                        <option value="">Select</option>
                        {extras?.map((extra, i) => {
                            return (<option key={`${extra}_${i}`} value={extra}>{extra}</option>)
                        })}
                    </select>
                </label>
                <label className="flex mb-2">
                    <span className='w-24 flex-none'>Ref no.</span>
                    <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2 -ml-2 w-full dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("bookingNo")} />
                </label>
                <button className={`border border-black rounded p-1 px-12 hover:bg-neutral-300 dark:hover:bg-neutral-500 bg-neutral-100 dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="submit">Write</button>
            </form>
            <div className={`flex border border-black bg-neutral-200 dark:bg-neutral-800 border-opacity-50 p-2 gap-2 w-full rounded shadow-xl dark:border-opacity-30 dark:border-neutral-50`}>
                <label className="flex w-full">
                    <span className='w-24 flex-none'>Output</span>
                    <textarea className={`border border-black rounded h-32 p-1 pl-2 w-full border-opacity-30 dark:border-opacity-30 dark:border-neutral-50 dark:bg-neutral-700`} value={textToCopy} onChange={(event) => setTextToCopy(event.target.value)} />
                </label>
                <button className={`bg-neutral-100 dark:bg-neutral-300 border-opacity-40 h-fit border border-black rounded-full p-2 my-auto`} ref={copyButtonRef} onClick={handleCopy}>
                    {!copy ? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#222"><path d="M0 0h24v24H0z" fill="none" /><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
                    }
                </button>
            </div>
        </div>
    );
}
