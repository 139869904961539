import Feature2 from '../assets/project/preview_tinypilot_2.png'
import google from '../assets/images/google-play-badge.png'
import IconTiny from '../assets/project/icon_tinypilot.png'
import { Footer } from '../common/layouts/Footer';
import { LazyImage } from '../common/LazyImage';
import { Card } from '../common/styled/Card';


export const Home = () => {
  //return <div className="overflow-y-scroll w-full h-full" style={{ scrollSnapType: "y mandatory" }}>
  return <div className="w-full h-full" >
    <div className="pt-14 h-5/6">
      <Card className="mx-auto" children={<>
        <h1 className="text-2xl font-extrabold">Featured Game:</h1>
        <h1 className="text-white font-extrabold text-xl" style={{
          textShadow: 'rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px, rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px, rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px, rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px, rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px, rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px, rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px, rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px, rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px, rgb(0, 0, 0) 2.88051px -0.838247px 0px'
        }} >
          Tiny Pilot
        </h1>
        <LazyImage src={IconTiny} alt="Tiny Pilot Icon" className="h-24 w-24 border-2 border-black rounded" />
        <p className="pb-2 text-xl font-semibold">A retro 2D platformer for mobile.</p>
        <a href="https://play.google.com/store/apps/details?id=com.companyhill.tinypilot" target="_blank" rel="noopener noreferrer">
          <LazyImage src={google} className="w-32 h-auto" alt="Google" />
        </a>
      </>} />
    </div>
    <div className="pt-14 h-5/6">
      <Card className="mx-auto px-1" children={<>
        <LazyImage src={Feature2} alt="Featured Screenshot" className="w-full max-w-3xl md:max-w-xl border-2 border-black rounded-sm"></LazyImage>
        <h1 className="text-xl font-semibold px-6">
          Face challenging bosses to unlock new levels.
        </h1>
      </>} />
    </div>
    <div className="pt-14 h-5/6">
      <div className='flex flex-col h-full'>
        <Card className="mx-auto mb-auto" children={<>
          <h1 className="text-xl text-center font-bold">
            And always remember...
          </h1>
          <p className='text-lg text-center'>
            You are a pilot. And you are tiny.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.companyhill.tinypilot" target="_blank" rel="noopener noreferrer"><LazyImage src={google} className="w-32 h-auto m-auto mt-6" alt="Google"></LazyImage></a>
        </>} />
        <Footer />
      </div>
    </div>
  </div>
}

