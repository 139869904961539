import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom"
import {App} from './App'
import {AppProvider} from './contexts/AppContext'
import {AuthProvider} from './contexts/AuthContext'
import {UserProvider} from './contexts/UserContext'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <AppProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)