import {useCallback, useEffect, useState} from 'react';

import {useStopwatch} from './contexts/StopwatchContext';

import Controls from './components/Controls';
import LapDisplay from './components/LapDisplay';
import MainDisplay from './components/MainDisplay';
import {formatTime, lapDisplayFormat} from './utils/utils'
import {WebApp} from '../../../../common/styled/WebApp';

export const StopwatchApp = () => {
    const {previousEvent,
        currentCount,
        currentLapCount,
        totalLapTimes,
        lapDurations,
        isActive} = useStopwatch()
    const [clockDisplay, setClockDisplay] = useState<string>("")
    const [milClockDisplay, setMilClockDisplay] = useState<string>("")
    const [lapDisplay, setLapDisplay] = useState<string>("")
    const [totalLapDisplay, setTotalLapDisplay] = useState<string>("")
    const [format, setFormat] = useState<string>("seconds")

    const checkFormat = useCallback((mainCount: number) => {
        if (mainCount >= 86400000) {
            if (format !== "days") {
                setFormat("days")
            }
            return
        } else if (mainCount >= 36000000) {
            if (format !== "doubleHours") {
                setFormat("doubleHours")
            }
            return
        } else if (mainCount >= 3600000) {
            if (format !== "singleHours") {
                setFormat("singleHours")
            }
            return
        } else if (mainCount >= 600000) {
            if (format !== "doubleMinutes") {
                setFormat("doubleMinutes")
            }
            return
        } else if (mainCount >= 60000) {
            if (format !== "singleMinutes") {
                setFormat("singleMinutes")
            }
            return
        }
        if (format !== "seconds") {
            setFormat("seconds")
        }
        return
    }, [format])

    const updateTime = useCallback(() => {
        if (previousEvent.event === "clear") {
            setFormat("seconds")
            setMilClockDisplay("00")
            setClockDisplay("00")
            return
        }
        let mainCount = isActive ? Date.now() - previousEvent.time + currentCount : currentCount
        checkFormat(mainCount)
        let {main, ms} = mainDisplayFormat(mainCount, format)
        let tLapDisplay = lapDisplayFormat(mainCount, format)
        setTotalLapDisplay(tLapDisplay)
        setClockDisplay(main)
        setMilClockDisplay(ms)
        if (totalLapTimes?.length > 0) {
            let lap = lapDisplayFormat(isActive ? Date.now() - previousEvent.time + currentLapCount : currentLapCount, format)
            setLapDisplay(lap)
        }
    }, [isActive, currentCount, previousEvent.event, previousEvent.time, totalLapTimes, format, checkFormat, currentLapCount]);



    useEffect(() => {
        let interval: any = null;
        updateTime()
        if (isActive) {
            interval = setInterval(updateTime, 10);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive, previousEvent.event, updateTime]);

    function mainDisplayFormat(time: number, format: string) {
        let {days, hours, minutes, seconds, milliseconds} = formatTime(time)
        let s = (seconds < 10) ? '0' + seconds : seconds.toString()
        let ms = (milliseconds < 10) ? '0' + milliseconds : milliseconds.toString().substring(0, 2)
        let main = ""
        if (format === "days") {
            main = days.toString() + ":"
            main += (hours < 10) ? '0' + hours + ":" : hours.toString() + ":"
            main += (minutes < 10) ? '0' + minutes + ":" : minutes.toString() + ":"
            main += s
            return {main, ms}
        }
        if (format === "doubleHours" || format === "singleHours") {
            main += hours.toString() + ":"
            main += (minutes < 10) ? '0' + minutes + ":" : minutes.toString() + ":"
            main += s
            return {main, ms}
        }
        if (format === "doubleMinutes" || format === "singleMinutes") {
            main += minutes.toString() + ":"
            main += s
            return {main, ms}
        }
        main += s
        return {main, ms}
    }

    return (
        <WebApp title={'Stopwatch'} className="md:h-auto md:mx-auto" children={
            <div className="flex flex-col w-full h-full md:grid md:grid-cols-2 md:h-96 md:px-12 md:gap-x-12">
                <div className={`flex justify-center w-full ${lapDurations?.length > 0 ? "pt-12 md:pt-0" : "pt-24 md:pt-0"}`}>
                    <MainDisplay clockDisplay={clockDisplay} milClockDisplay={milClockDisplay} />
                </div>
                <div className={`flex flex-col items-center w-full`}>
                    <p className="w-full text-center font-bold hidden md:block">Laps</p>
                    <LapDisplay lapDisplay={lapDisplay} totalLapDisplay={totalLapDisplay} format={format} />
                </div>
                <div className="flex flex-col w-full justify-end items-center grow pb-12"><Controls /></div>
            </div>
        } />
    );
}

