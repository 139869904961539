export const Privacy = () => {
    return (
        <div className="flex flex-col gap-2 justify-center h-full dark:text-neutral-50 p-12 md:p-20 bg-black bg-opacity-50">
            <h1 className="font-bold text-sm">Privacy Policy for Company Hill Games</h1>
            <p>Last updated: 2024/02/23</p>
            <p>
                This Privacy Policy describes how Company Hill, the developer of Tiny Pilot ("the Game"), collects, uses, and shares information when you use our Game. We are committed to protecting your privacy and handling any personal information we obtain from you with care and respect.
            </p>
            <h1 className="font-bold text-sm">Information Collection and Use</h1>
            <p>
                The Game is a simple 2D game with no online features and does not collect any personal or sensitive user data. As such, we do not store or process any personal information about the players.
            </p>
            <h1 className="font-bold text-sm">No Sensitive Permissions or Data Access</h1>
            <p>
                The Game does not request or require access to sensitive permissions or data on your device. No personal data is collected, used, or shared with any third parties.
            </p>
            <h1 className="font-bold text-sm">Children’s Privacy</h1>
            <p>
                Although the Game does not target children as its primary audience or collect personal information from users, parents and guardians should supervise their children's gaming activities.
            </p>
            <h1 className="font-bold text-sm">Changes to This Privacy Policy</h1>
            <p>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
            </p>
            <h1 className="font-bold text-sm">Contact Us</h1>
            <p>
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at [Your Contact Information Here].
            </p>
            <h1 className="font-bold text-sm">Acknowledgment</h1>
            <p>
                By installing and playing Tiny Pilot, you acknowledge that you have read and understood this privacy policy and agree to its terms.
            </p>
            <p>This Privacy Policy does not require the use of any personal information as the Game has no capability to store such information. If any changes occur that affect user data handling in future versions of the Game, this policy will be updated to accurately reflect those changes.
            </p>

        </div>
    );
}