import { useMemo } from 'react';

export const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), [])
  return (
    <div className="flex justify-evenly items-center w-full md:h-24 md:border-t py-8 md:py-12 mt-24
        text-xs md:text-base
        bg-white dark:bg-black md:dark:bg-neutral-900
        border-black dark:border-neutral-50 
        border-opacity-25 dark:border-opacity-25
        dark:text-neutral-50">
      <div>
        <span className="">{`© ${year} Company Hill`}</span>
      </div>
      <div>
        <a href="http://play.google.com/store/apps/dev?id=5687874818441658445" target="_blank" rel="noreferrer" className="block">Google Dev Account</a>
      </div>
    </div>
  )
}
