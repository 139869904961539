import { StopwatchProvider } from './contexts/StopwatchContext'
import { StopwatchApp } from './StopwatchApp';

export const StopwatchWrapper = () => {
  return (
    <StopwatchProvider>
      <StopwatchApp />
    </StopwatchProvider>
  )
}
