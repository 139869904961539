import React, {useContext, useEffect, useReducer, useState} from 'react'
import {useAuth} from '../../../../../contexts/AuthContext';
import {doc, onSnapshot, updateDoc} from 'firebase/firestore';
import {db} from '../../../../../firebase';

type BookingState = {
    airports: string[],
    hotels: string[],
    seatClasses: string[],
    airlines: string[],
    names: string[],
    extras: string[],
}

type BookingType = {
    state: BookingState
    updateBooking: (booking: BookingState) => Promise<void>
}


type Action = {
    type: 'UPDATE',
    payload: {
        airports: string[],
        hotels: string[],
        seatClasses: string[],
        airlines: string[],
        names: string[],
        extras: string[],
    }
}

const reducer = (state: BookingState, action: Action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                airports: action.payload.airports,
                hotels: action.payload.hotels,
                seatClasses: action.payload.seatClasses,
                airlines: action.payload.airlines,
                names: action.payload.names,
                extras: action.payload.extras,
            }
        default:
            return state
    }
}

const initialState: BookingState = {
    airports: [],
    hotels: [],
    seatClasses: [],
    airlines: [],
    names: [],
    extras: [],
}
const BookingContext = React.createContext<BookingType>({
    state: initialState,
    updateBooking: () => new Promise(() => { }),
})


export const useBooking = () => useContext(BookingContext)

export const BookingProvider = ({children}: any) => {
    const {currentUser} = useAuth()
    const [state, dispatch] = useReducer(reducer, initialState)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!currentUser) return;
        const unsub = onSnapshot(doc(db, 'bookings/' + currentUser.uid), (doc) => {
            if (doc.exists()) {
                const data: BookingState = doc.data() as BookingState;
                setLoading(false)

                dispatch({
                    type: 'UPDATE',
                    payload: {
                        airports: data.airports,
                        hotels: data.hotels,
                        seatClasses: data.seatClasses,
                        airlines: data.airlines,
                        names: data.names,
                        extras: data.extras ?? [],
                    }
                })
            }
        });
        return unsub;
    }, [currentUser]);

    async function updateBooking(booking: BookingState) {
        if (!currentUser) return;
        await updateDoc(doc(db, `bookings/${currentUser.uid}`), booking);
    }

    const value = {
        state,
        updateBooking,
    }

    return (
        <BookingContext.Provider value={value}>
            {!loading ? children : <></>}
        </BookingContext.Provider>
    )
}