import {Link, Route, Routes} from "react-router-dom"
import {LogoSVG} from "../svg/LogoSVG"
import {ReactNode} from "react"
import {BackNav} from "./BackNav"
import {AvatarButton} from "./AvatarButton"
import {ThemeButton} from "./ThemeButton"
import {useAuth} from "../../contexts/AuthContext"

type Props = {
    setSelected: (selection: string) => void
    selected: string
    openModal: (modal: ReactNode) => void
    closeModal: () => void
}

export const TopNav = ({setSelected, selected, openModal, closeModal}: Props) => {
    const {currentUser} = useAuth()
    return (
        <header>
            <nav className="flex justify-center md:justify-start items-center w-full 
                h-24 
                border-b
                dark:bg-neutral-900 dark:border-neutral-50 dark:border-opacity-25">
                <Routes>
                    <Route path="/account" element={<><BackNav /><ThemeButton /></>} />
                    <Route path="/webapps/*" element={<><BackNav /> {currentUser && <AvatarButton openModal={openModal} closeModal={closeModal} />}</>} />
                    <Route path="/*" element={
                        <>
                            <span onClick={() => setSelected("featured")} className="md:pr-20 lg:pr-40 whitespace-nowrap ">
                                <Link to="featured">
                                    <LogoSVG className="w-16 h-16 md:ml-4" />
                                </Link>
                            </span>
                            <ul className="hidden md:flex md:gap-20 grow lg:gap-32 items-end pb-2 text-black font-bold text-lg h-full">
                                <li onClick={() => setSelected("featured")} className={`${selected === "featured" ? "underline" : "hover:underline"} dark:text-neutral-50`}><Link to="featured">Featured</Link></li>
                                <li onClick={() => setSelected("projects")} className={`${selected === "projects" ? "underline" : "hover:underline"} dark:text-neutral-50`}><Link to="projects/web">Projects</Link></li>
                                <li onClick={() => setSelected("contact")} className={`${selected === "contact" ? "underline" : "hover:underline"} dark:text-neutral-50`}><Link to="contact">Contact</Link></li>
                            </ul>
                            {currentUser && <AvatarButton openModal={openModal} closeModal={closeModal} />}
                        </>
                    } />
                </Routes>
            </nav>
        </header>
    )
}        
