import { AUTH_SUCCESS, useAuth } from '../../contexts/AuthContext'
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorSVG } from '../../common/svg/ErrorSVG';
import { Form } from '../../common/styled/Form';
import { Input } from '../../common/styled/Input';
import { SubmitButton } from '../../common/styled/SubmitButton';
import { GoogleButton } from './GoogleButton';
import { Divider } from '../../common/styled/Divider';
import { PageCard } from '../../common/styled/decorators/PageCard';

export const Reauthentication = () => {
    const { signIn: login, googleLogin } = useAuth();
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const { currentUser } = useAuth()
    let navigate = useNavigate();
    let location: any = useLocation();

    //modal: "changePassword", data: newPassword, from: from
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (email.length === 0 || password.length === 0) {
            setError("Please fill in all fields.")
            return
        }
        setLoading(true)
        const result = await login(email, password);
        if (result === AUTH_SUCCESS) {
            navigate("/account", { state: location.state, replace: true });
        } else {
            setError(result)
            setLoading(false)
        }
    }

    const googleClick = async () => {
        setLoading(true)
        const result = await googleLogin()
        if (result === AUTH_SUCCESS) {
            navigate("/account", { state: location.state, replace: true });
        } else {
            setError(result)
            setLoading(false)
        }
    }

    return (
        <PageCard children={<>
            <h1 className="font-bold text-xl w-full">Reauthentication Required</h1>
            <div className="flex justify-start w-full">
                <p>To continue, first verify it’s you</p>
            </div>
            <Form onSubmit={handleSubmit} children={<>
                <div className="w-full">
                    <label>Email</label>
                    <Input setState={setEmail} state={email} type="email" placeholder="Email" />
                </div>
                <div className="w-full">
                    <label>Password</label>
                    <Input setState={setPassword} state={email} type="password" placeholder="Password" />
                </div>
                <div className="flex justify-between w-full">
                    {error.length > 0 &&
                        <div className="flex justify-start w-full">
                            <ErrorSVG />
                            <div className="ml-1">{error}</div>
                        </div>
                    }
                </div>
                <SubmitButton
                    disabled={loading}
                    text="Next"
                />
            </>} />
            {currentUser?.providerData[0].providerId !== "password" && <>
                <Divider text="or" />
                <GoogleButton loading={loading} googleClick={googleClick} />
            </>}
        </>} />);
}