import {useState} from 'react';
import {db} from "../firebase";
import {collection, addDoc} from "firebase/firestore";
import {Footer} from '../common/layouts/Footer';
import {CheckMarkSVG} from '../common/svg/CheckMarkSVG';
import {ErrorSVG} from '../common/svg/ErrorSVG';
import {Input} from '../common/styled/Input';
import {Textarea} from '../common/styled/Textarea';
import {SubmitButton} from '../common/styled/SubmitButton';
import {Form} from '../common/styled/Form';
import {PageCard} from '../common/styled/decorators/PageCard';

export const Contact = () => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [subject, setSubject] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [error, setError] = useState(false)
    const [sent, setSent] = useState(false)
    const [loading, SetLoading] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError(false)
        setSent(false)
        let complete = true;
        if (name.length < 1) complete = false
        if (email.length < 1) complete = false
        if (message.length < 1) complete = false
        if (!complete) {
            setError(true)
            return
        } else {
            setError(false)
            SetLoading(true)
        }
        //await setDoc(doc(db, "messages","uniqueId"), {x:0});
        // Add a new document with a generated id.
        await addDoc(collection(db, "messages"), {
            name: name,
            emailAddress: email,
            subject: subject,
            message: message
        });
        setSent(true)
        SetLoading(false)
        setName("")
        setEmail("")
        setSubject("")
        setMessage("")
    }

    return (
        <>
            <PageCard className="mx-auto mb-auto mt-4 md:mt-14" children={
                <Form onSubmit={handleSubmit} children={<>
                    <h1 className="w-full font-bold text-xl mr-2">Contact Support</h1>
                    <div className="w-full">
                        <label>Name</label>
                        <Input state={name} setState={setName} type='text' />
                    </div>
                    <div className="w-full">
                        <label>Email</label>
                        <Input state={email} setState={setEmail} type='email' />
                    </div>
                    <div className="w-full">
                        <label>Subject</label>
                        <Input state={subject} setState={setSubject} type='text' />
                    </div>
                    <div className="w-full">
                        <p>Message</p>
                        <Textarea className="h-28" state={message} setState={setMessage} />
                    </div>
                    <div className="flex justify-between w-full h-6">
                        {error &&
                            <div className="flex justify-start w-full">
                                <ErrorSVG />
                                <p className="ml-1">Please fill in all information.</p>
                            </div>
                        }
                        {
                            (sent && !error) &&
                            <div className="flex justify-start w-full">
                                <CheckMarkSVG />
                                <p className="ml-1">Message Sent.</p>
                            </div>
                        }
                    </div>
                    <SubmitButton disabled={loading} text={'Send'} />
                </>} />

            } />
            <Footer />
        </>
    );
}
