type Props = {
  clockDisplay: string
  milClockDisplay: string
}

export default function MainDisplay({ clockDisplay, milClockDisplay }: Props) {
  return (
    <div className="flex justify-center items-center  border border-black h-48 w-48 rounded-full bg-white dark:bg-neutral-800">
      <div>
        <div className="text-5xl font-thin -mb-1">{clockDisplay}</div>
        <div className="w-full text-xl text-right h-2">{milClockDisplay}</div>
      </div>
    </div>
  )
}
