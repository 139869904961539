import {Link, useNavigate} from "react-router-dom";
import {BackMainSVG} from "../svg/BackMainSVG";
import {BackWebSVG} from "../svg/BackWebSVG";
import {LogoSVG} from "../svg/LogoSVG";

export const BackNav = () => {
    const navigate = useNavigate()

    return (
        <>
            {/* Mobile */}
            <div className="md:hidden">
                <button onClick={() => navigate(-1)} className="absolute left-0 m-1 w-16 h-16 p-4" >
                    <BackWebSVG className="dark:fill-neutral-100" />
                </button>
                <Link to={"/featured"}>
                    <LogoSVG className="w-16 h-16" />
                </Link>
            </div>
            {/* Mobile */}
            <button onClick={() => navigate(-1)} className="hidden md:flex items-center">
                <BackMainSVG className="mt-1 w-10 h-10 -mx-1 dark:fill-neutral-100" />
                <LogoSVG className="w-16 h-16" />
            </button>
        </>
    )
}
