import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";

export const RequireNoAuth = () => {
    let {currentUser, emailVerified} = useAuth()

    if (currentUser === null || currentUser === undefined) {
        return <Outlet />
    }

    if (!emailVerified) {
        return <Navigate to="/verification" state={{from: "/"}} replace />
    }
    return <Navigate to="/" replace />
}
