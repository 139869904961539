import { ReactNode } from "react"

type Props = {
    className?: string
    children: ReactNode
    propogation?: boolean
}

export const Card = (({ children, propogation, className }: Props) => {
    return (
        <div onClick={(e) => { !propogation && e.stopPropagation() }} className={`flex flex-col items-center gap-5 
        w-full max-w-screen-md p-6 py-10 md:p-12
        shadow-md md:shadow-lg border rounded 
        bg-white dark:bg-neutral-900
        dark:border-neutral-50 
        border-opacity-25 dark:border-opacity-25
        dark:text-neutral-50 ${className}`}>
            {children}
        </div>
    )
})