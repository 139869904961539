import {useState} from "react"
import {AUTH_REAUTH, AUTH_SUCCESS, useAuth} from "../../contexts/AuthContext"
import {useLocation, useNavigate} from "react-router-dom"
import {ErrorSVG} from "../svg/ErrorSVG"
import {CheckMarkSVG} from "../svg/CheckMarkSVG"
import {Form} from "../styled/Form"
import {SubmitButton} from "../styled/SubmitButton"
import {Input} from "../styled/Input"
import {CloseButton} from "./CloseButton"
import {ModalCard} from "../styled/decorators/ModalCard"
type Props = {
    closeModal: () => void
}

export const ChangeEmail = ({closeModal}: Props) => {
    const location: any = useLocation();
    const from = location.state?.from || "/";
    const navigate = useNavigate()

    const [newEmail, setNewEmail] = useState(location.state?.data || "")
    const [confirmNewEmail, setConfirmNewEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const {changeEmail} = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setMessage("")
        if (newEmail === "" || confirmNewEmail === "") {
            setError("Please fill in all emails.")
            return
        }
        if (newEmail !== confirmNewEmail) {
            setError("Emails don't match.")
            return
        }
        setError("")
        setLoading(true)
        const result = await changeEmail(newEmail);

        if (result === AUTH_SUCCESS) {
            setMessage("Email changed successfully.")
        } else if (result === AUTH_REAUTH) {
            closeModal()
            navigate("/reauthentication", {state: {modal: "changeEmail", from: from}})
            return
        } else {
            setError(result)
        }
        setLoading(false)
    }
    return (
        <>
            <div className="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-black opacity-80 z-40" />
            <div onClick={closeModal} className="fixed flex justify-center items-center top-0 left-0  w-full h-full z-50">
                <ModalCard children={
                    <>
                        <CloseButton closeModal={closeModal} />
                        <div className="flex flex-col gap-2">
                            <h1 className="font-bold text-xl w-full">Change Email</h1>
                            <div className="flex justify-start w-full mb-4">
                                <p> Enter your new email. </p>
                            </div>
                            <Form onSubmit={handleSubmit} children={<>
                                <div className="w-full">
                                    <label>New Email</label>
                                    <Input setState={setNewEmail} state={newEmail} type="email" placeholder="Email" />
                                </div>
                                <div className="w-full">
                                    <label>Confirm New Email</label>
                                    <Input setState={setConfirmNewEmail} state={confirmNewEmail} type="email" placeholder="Email" />
                                </div>
                                <div className="flex justify-between w-full">
                                    {error.length > 0 &&
                                        <div className="flex justify-start w-full">
                                            <ErrorSVG />
                                            <div className="ml-1">{error}</div>
                                        </div>

                                    }
                                    {
                                        (message && !error) &&
                                        <div className="flex justify-start w-full">
                                            <CheckMarkSVG />
                                            <p className="ml-1">Email Changed</p>
                                        </div>
                                    }

                                </div>
                                <SubmitButton disabled={loading} text={"Change Email"} />
                            </>} />
                        </div>
                    </>} />
            </div>
        </>
    )
}