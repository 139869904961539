import { ReactNode } from "react"
import { Card } from "../Card"

type Props = {
    children: ReactNode
    className?: string
}
export const PageCard = (({ children }: Props) => {
    return (
        <Card className="mx-auto mb-auto mt-4 md:mt-14" children={children} />
    )
})