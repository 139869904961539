import React from 'react'
import { useStopwatch } from '../contexts/StopwatchContext';
import ClearSVG from "../svgs/ClearSVG"
import PauseSVG from "../svgs/PauseSVG"
import PlaySVG from "../svgs/PlaySVG"
import LapSVG from "../svgs/LapSVG"

export default function Controls() {
    const { handleControlEvent, currentCount, isActive } = useStopwatch()
    const handleButton = ((event: string) => {
        if (event === "middle") {
            event = isActive ? "pause" : "active"
        }
        handleControlEvent({ event: event, time: Date.now() })
    })

    return (
        <ul className="flex gap-2 md:gap-10 mt-4">
            <li className="">
                <button className="w-20 h-20 border border-black bg-white p-1 rounded-full dark:bg-neutral-800 disabled:invisible" disabled={currentCount === 0 && !isActive} onClick={() => handleButton("clear")}>
                    {<ClearSVG className="w-full h-full fill-black dark:fill-neutral-50" />}
                </button>
            </li>
            <li>
                <button className={`w-20 h-20 flex justify-center border border-black bg-white p-1 dark:bg-neutral-800 rounded-full`} onClick={() => handleButton("middle")}>
                    {isActive ?
                        <PauseSVG className="w-full h-full fill-black dark:fill-neutral-50" />
                        :
                        <PlaySVG className="w-full h-full fill-black dark:fill-neutral-50" />
                    }
                </button>
            </li>
            <li>
                <button className="w-20 h-20 border border-black bg-white p-1 rounded-full dark:bg-neutral-800 disabled:invisible" disabled={!isActive} onClick={() => handleButton("lap")}>
                    {<LapSVG className="w-full h-full fill-black dark:fill-neutral-50" />}
                </button>
            </li>
        </ul>
    )
}
