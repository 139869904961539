import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getDatabase} from "firebase/database";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";
import {getFunctions} from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyApi25fs9iVapoRjkKj6Y1zM0n-aF_HESA",
  authDomain: "company-hill.firebaseapp.com",
  databaseURL: "https://company-hill.firebaseio.com",
  projectId: "company-hill",
  storageBucket: "company-hill.appspot.com",
  messagingSenderId: "1018926333934",
  appId: "1:1018926333934:web:ad8fecab7042e76ca998ff",
  measurementId: "G-ZM8RH1FFSC"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(firebaseApp);
export const db = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);
//connectFunctionsEmulator(functions, "localhost", 5001);
