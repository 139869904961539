import {ReactNode} from "react"

type Props = {
    className?: string
    topNav?: ReactNode
    children: ReactNode
    title: string
}

const textShadow = `#000 3px 0px 0px, #000 2.83487px 0.981584px 0px, #000 2.35766px 1.85511px 0px, 
#000 1.62091px 2.52441px 0px, #000 0.705713px 2.91581px 0px, #000 -0.287171px 2.98622px 0px, 
#000 -1.24844px 2.72789px 0px, #000 -2.07227px 2.16926px 0px, #000 -2.66798px 1.37182px 0px, 
#000 -2.96998px 0.42336px 0px, #000 -2.94502px -0.571704px 0px, #000 -2.59586px -1.50383px 0px, 
#000 -1.96093px -2.27041px 0px, #000 -1.11013px -2.78704px 0px, #000 -0.137119px -2.99686px 0px, 
#000 0.850987px -2.87677px 0px, #000 1.74541px -2.43999px 0px, #000 2.44769px -1.73459px 0px, 
#000 2.88051px -0.838247px 0px`

export const WebApp = (({children, className, title, topNav}: Props) => {
    return (
        <div className={`flex flex-col items-center gap-5 
        h-full p-4
        m-2 md:mt-2 
        shadow-md md:shadow-lg border rounded 
        bg-neutral-200 dark:bg-neutral-900
        border-black
        dark:border-neutral-50 
        dark:border-opacity-25
        dark:text-neutral-50
        ${className}`}>
            <div className="flex justify-start w-full">
                <p className="flex-1 font-semibold text-white" style={{textShadow: textShadow}}>{title}</p>
                {topNav}
            </div>
            {children}
        </div>
    )
})