import {useApp} from "../../contexts/AppContext";

type Props = {
    className?: string;
}

export const LogoSVG = ({className}: Props) => {
    const {isDark} = useApp();
    return (
        <svg className={className} viewBox="0 0 144 144" xmlns="http://www.w3.org/2000/svg" shapeRendering={"crispEdges"}>
            <rect id="XMLID_51_" x="18" y="90" fill="#F7941E" width="108" height="9" />
            <rect id="XMLID_52_" x="126" y="45" fill="#F7941E" width="9" height="45" />
            <rect id="XMLID_8_" x="126" y="90" fill="#E96424" width="9" height="9" />
            <rect id="XMLID_9_" x="9" y="90" width="9" height="9" />
            <rect id="XMLID_38_" x="9" y="27" width="9" height="9" />
            <polygon id="XMLID_39_" points="135,36 135,99 9,99 9,45 9,36 0,36 0,45 0,108 8,108 9,108 144,108 144,99 144,36 	" />
            <rect id="XMLID_40_" x="18" y="36" width="9" height="9" />
            <rect id="XMLID_41_" x="27" y="45" width="9" height="9" />
            <rect id="XMLID_42_" x="36" y="54" width="9" height="9" />
            <rect id="XMLID_43_" x="45" y="45" width="9" height="9" />
            <rect id="XMLID_44_" x="54" y="36" width="9" height="9" />
            <polygon id="XMLID_45_" points="81,36 81,27 72,27 63,27 63,36 72,36" />
            <rect id="XMLID_46_" x="81" y="36" width="9" height="9" />
            <rect id="XMLID_47_" x="90" y="45" width="9" height="9" />
            <rect id="XMLID_48_" x="99" y="54" width="9" height="9" />
            <rect id="XMLID_49_" x="108" y="45" width="9" height="9" />
            <rect id="XMLID_50_" x="117" y="36" width="9" height="9" />
            <rect id="XMLID_67_" x="126" y="27" width="9" height="9" />
            <polygon id="XMLID_7_" fill="#FFF200" points="18,45 18,36 9,36 9,90 18,90 18,54 27,54 27,45 	" />
            <rect id="XMLID_53_" x="27" y="54" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_54_" x="36" y="63" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_55_" x="45" y="54" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_56_" x="54" y="45" fill="#FFF200" width="9" height="9" />
            <polygon id="XMLID_57_" fill="#FFF200" points="72,36 63,36 63,45 72,45 81,45 81,36" />
            <rect id="XMLID_58_" x="81" y="45" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_62_" x="90" y="54" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_63_" x="99" y="63" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_64_" x="108" y="54" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_65_" x="117" y="45" fill="#FFF200" width="9" height="9" />
            <rect id="XMLID_66_" x="126" y="36" fill="#FFF200" width="9" height="9" />
            <polygon id="XMLID_60_" fill="#FFD400" points="117,54 117,63 108,63 108,72 99,72 99,63 90,63 90,54 81,54 81,45 63,45 63,54 54,54 54,63 45,63 45,72 36,72 36,63 27,63 27,54 18,54 18,72 18,90 126,90 126,72 126,54 	" />
            <rect id="XMLID_61_" x="9" y="90" fill="#FFD400" width="9" height="9" />
            {isDark && <rect fill="black" x="0" y="114" width="144" height="28" />}
            <text transform="matrix(1 0 0 1 6 136)" fill="white" stroke="white" strokeWidth="1" fontFamily="Helvetica" fontSize="24px">company hill</text>
        </svg>
    )
}