import {useApp} from "../../contexts/AppContext"
import {DarkModeSVG} from "../svg/DarkModeSVG"
import {LightModeSVG} from "../svg/LightModeSVG"

export const ThemeButton = () => {
    const {isDark, changeTheme} = useApp();

    return (<button onClick={changeTheme} className="absolute right-0 top-0 h-8 w-8 mr-5 md:mr-9 mt-9 ">
        {isDark ? <LightModeSVG className="dark:fill-neutral-50" /> : <DarkModeSVG />}
    </button>)
}