import IconCalc from '../../assets/project/icon_calc.png'
import IconJousting from '../../assets/project/icon_jousting.png'
import IconShowdown from '../../assets/project/icon_showdown.png'
import IconSmash from '../../assets/project/icon_smash.png'
import IconSurface from '../../assets/project/icon_surface.png'
import IconTiny from '../../assets/project/icon_tinypilot.png'

import Jousting1 from '../../assets/project/jousting_1.png'
import Jousting2 from '../../assets/project/jousting_2.png'
import Jousting3 from '../../assets/project/jousting_3.png'
import Showdown1 from '../../assets/project/showdown_1.png'
import Showdown2 from '../../assets/project/showdown_2.png'
import Showdown3 from '../../assets/project/showdown_3.png'
import Showdown4 from '../../assets/project/showdown_4.png'
import Showdown5 from '../../assets/project/showdown_5.png'
import Smash1 from '../../assets/project/smash_1.png'
import Smash2 from '../../assets/project/smash_2.png'
import Surface1 from '../../assets/project/surface_1.png'
import Tiny1 from '../../assets/project/tinypilot_1.png'
import Tiny2 from '../../assets/project/tinypilot_2.png'
import Tiny3 from '../../assets/project/tinypilot_3.png'
import Toolbox1 from '../../assets/project/toolbox_1.png'
import Toolbox2 from '../../assets/project/toolbox_2.png'

import PreviewJousting1 from '../../assets/project/preview_jousting_1.png'
import PreviewJousting2 from '../../assets/project/preview_jousting_2.png'
import PreviewJousting3 from '../../assets/project/preview_jousting_3.png'
import PreviewShowdown1 from '../../assets/project/preview_showdown_1.png'
import PreviewShowdown2 from '../../assets/project/preview_showdown_2.png'
import PreviewShowdown3 from '../../assets/project/preview_showdown_3.png'
import PreviewShowdown4 from '../../assets/project/preview_showdown_4.png'
import PreviewShowdown5 from '../../assets/project/preview_showdown_5.png'
import PreviewSmash1 from '../../assets/project/preview_smash_1.png'
import PreviewSmash2 from '../../assets/project/preview_smash_2.png'
import PreviewSurface1 from '../../assets/project/preview_surface_1.png'
import PreviewTiny1 from '../../assets/project/preview_tinypilot_1.png'
import PreviewTiny2 from '../../assets/project/preview_tinypilot_2.png'
import PreviewTiny3 from '../../assets/project/preview_tinypilot_3.png'
import PreviewToolbox1 from '../../assets/project/preview_toolbox_1.png'
import PreviewToolbox2 from '../../assets/project/preview_toolbox_2.png'

import Harvester1 from '../../assets/project/harvester_1.png'
import Harvester2 from '../../assets/project/harvester_2.png'
import Trivago1 from '../../assets/project/trivago_1.png'
import Trivago2 from '../../assets/project/trivago_2.png'
import Trivago3 from '../../assets/project/trivago_3.png'
import Trivago4 from '../../assets/project/trivago_4.png'
import Trivago5 from '../../assets/project/trivago_5.png'

import PreviewHarvester1 from '../../assets/project/preview_harvester_1.png'
import PreviewHarvester2 from '../../assets/project/preview_harvester_2.png'
import PreviewTrivago1 from '../../assets/project/preview_trivago_1.png'
import PreviewTrivago2 from '../../assets/project/preview_trivago_2.png'
import PreviewTrivago3 from '../../assets/project/preview_trivago_3.png'
import PreviewTrivago4 from '../../assets/project/preview_trivago_4.png'
import PreviewTrivago5 from '../../assets/project/preview_trivago_5.png'



export const mobileProjects = [
  {
    key: "tinyPilot",
    title: "Tiny Pilot",
    description: "Fly through three levels of non-stop action! Navigate through difficult terrains. Survive numerous enemies. Bosses included.",
    icon: IconTiny,
    previews: [PreviewTiny1, PreviewTiny2, PreviewTiny3],
    slides: [Tiny1, Tiny2, Tiny3],
    esrb: "everyone",
    link: "https://play.google.com/store/apps/details?id=com.companyhill.tinypilot&hl=en"
  },
  {
    key: "showdown",
    title: "2P Showdown",
    description: "A simple reaction-speed 2-player game settles it all with a fast draw. Gun down your opponent before he puts you down with his own quick draw.",
    icon: IconShowdown,
    previews: [PreviewShowdown1, PreviewShowdown2, PreviewShowdown3, PreviewShowdown4, PreviewShowdown5],
    slides: [Showdown1, Showdown2, Showdown3, Showdown4, Showdown5],
    esrb: "everyone10",
    link: "https://play.google.com/store/apps/details?id=simplerigs.com.a2pshowdown&hl=en"
  },
  {
    key: "smash",
    title: "2P Smash",
    description: "Fun and easy to play! Share a few minutes with that special someone. Hold down both buttons to smash!",
    icon: IconSmash,
    previews: [PreviewSmash1, PreviewSmash2],
    slides: [Smash1, Smash2],
    esrb: "everyone",
    link: "https://play.google.com/store/apps/details?id=com.simplerigs.a2psmash&hl=en"
  },
  {
    key: "jousting",
    title: "2P Jousting",
    description: "Knock your opponent off his horse before he takes you down first. Hold down both buttons to charge!",
    icon: IconJousting,
    previews: [PreviewJousting1, PreviewJousting2, PreviewJousting3],
    slides: [Jousting1, Jousting2, Jousting3],
    esrb: "everyone",
    link: "https://play.google.com/store/apps/details?id=simplerigs.com.a2pjousting&hl=en"
  },
  {
    key: "surface",
    title: "Surface Paint",
    description: "Surface Paint is a simple and intuitive drawing application. It allows for numerous sketches, layerings, and palettes. Drawings can easily be exported to the gallery or shared.",
    icon: IconSurface,
    previews: [PreviewSurface1],
    slides: [Surface1],
    esrb: "everyone",
    link: "https://play.google.com/store/apps/details?id=simplerigs.com.surfacepaint&hl=en"
  }, {
    key: "toolbox",
    title: "Toolbox Calculator",
    description: "Toolbox calculator is a carpentry app that is useful for working with Imperial/ US customary calculations, converting between the metric system, and making right-angled triangle calculations.",
    icon: IconCalc,
    previews: [PreviewToolbox1, PreviewToolbox2],
    slides: [Toolbox1, Toolbox2],
    esrb: "everyone",
    link: "https://play.google.com/store/apps/details?id=simplerig.com.toolboxcalculator&hl=en"
  },

]

export const webProjects = [
  {
    key: "flight",
    title: "Booking Assistant",
    description: "A webapp that helps with pipelining company bookings.",
    local: true,
    link: "bookingassistant"
  },
  {
    key: "sub",
    title: "Substring Sequencer",
    description: "A webapp that iterates through a string replacing and appending to it.",
    local: true,
    link: "substringsequencer"
  },
  {
    key: "stop",
    title: "Stopwatch",
    description: "An online stopwatch.",
    local: true,
    link: "stopwatch"
  },
]


export const extensionProjects = [{
  key: "trivago",
  title: "Trivago Extension",
  description: "A multifunctional browser extension for automating quality assurance testing based on user inputs, managing bookmarks, storing and referencing data, importing .csv files, and dynamically linking webdata.",
  previews: [PreviewTrivago1, PreviewTrivago2, PreviewTrivago3, PreviewTrivago4, PreviewTrivago5],
  slides: [Trivago1, Trivago2, Trivago3, Trivago4, Trivago5],
},
{
  key: "harvester",
  title: "Web Harvesting Extension",
  description: "A browser extension that harvests content from a number of sites for offline personal use.",
  previews: [PreviewHarvester1, PreviewHarvester2],
  slides: [Harvester1, Harvester2],
}]
