import { useUser } from '../contexts/UserContext'
import { PigSVG } from './svg/animals/PigSVG'
import { CatSVG } from './svg/animals/CatSVG'
import { DogSVG } from './svg/animals/DogSVG'
import { RabbitSVG } from './svg/animals/RabbitSVG'

type Props = {
    className: string
    override?: {
        avatar: string
        bgColor: string
    }
}
//w-24 h-24 md:w-36 md:h-36 p-4 rounded-full border border-black border-opacity-25

export const Avatar = ({ className, override }: Props) => {
    const { avatar, avatarColor } = useUser()
    let holder = <CatSVG />
    switch (override ? override.avatar : avatar) {
        case "pig": holder = <PigSVG className="fill-black" />
            break
        case "dog": holder = <DogSVG className="fill-black" />
            break
        case "cat": holder = <CatSVG className="fill-black" />
            break
        case "rabbit": holder = <RabbitSVG className="fill-black" />
            break
    }

    return (
        <div className={`${className} border-black`} style={{
            backgroundColor: override ? override.bgColor : avatarColor
        }}>
            {holder}
        </div>
    )
}