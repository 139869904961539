import {Link} from 'react-router-dom'
import {Card} from '../../../common/styled/Card'
import {webProjects} from '../projectsData'

export const WebCards = () => {
    return (
        <>
            {
                webProjects.map((obj: any, i: number) => {
                    return (
                        <div key={`web${i}`} className="mt-14 h-auto">
                            <Card className="md:w-2/3 mx-auto h-auto" key={i} children=
                                {obj.local &&
                                    <Link className="p-12 w-full -m-12" to={`/webapps/${obj.link}`}>
                                        <div className="flex flex-col w-full h-full p-6">
                                            <h1 className="text-center font-bold text-xl">{obj.title}</h1>
                                            <p className="text-center">{obj.description}</p>
                                        </div>
                                    </Link>
                                } />
                        </div>
                    )
                })
            }
        </>
    )
}