import {projectCategories} from "../../shared/consts"
import {ProjectNavRow} from "./ProjectNavRow"

export const ProjectNav = () => {

    return (
        <div className="fixed top-24 md:top-36 md:mt-2 flex justify-center gap-4 md:gap-0 md:block w-full md:w-28 pt-2 md:pt-0 md:shadow-xl">
            <ProjectNavRow category={projectCategories.MOBILE} text="Mobile" link={projectCategories.MOBILE} />
            <ProjectNavRow category={projectCategories.WEB} text="Web" link={projectCategories.WEB} />
        </div>
    )
}