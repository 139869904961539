import React, { ReactChild, useCallback, useContext, useEffect, useState } from 'react'
import { getDatabase, ref, update, onValue } from "firebase/database";
import { useAuth } from '../../../../../contexts/AuthContext';

type Stopwatch = {
    handleControlEvent: (event: event) => void,
    previousEvent: event,
    currentCount: number,
    currentLapCount: number,
    totalLapTimes: number[],
    lapDurations: number[],
    isActive: boolean
}

const StopwatchContext = React.createContext<Stopwatch>({
    handleControlEvent: () => null,
    previousEvent: { time: 0, event: "clear" },
    currentCount: 0,
    currentLapCount: 0,
    totalLapTimes: [],
    lapDurations: [],
    isActive: false
})

export const useStopwatch = () => useContext(StopwatchContext)

type event = {
    time: number,
    event: string
}

export const StopwatchProvider = ({ children }: any) => {
    const { currentUser } = useAuth()
    const [previousEvent, setPreviousEvent] = useState<event>({ time: 0, event: "clear" })
    const [currentCount, setCurrentCount] = useState<number>(0);
    const [currentLapCount, setCurrentLapCount] = useState<number>(0);
    const [totalLapTimes, setTotalLapTimes] = useState<number[]>([]);
    const [lapDurations, setLapDurations] = useState<number[]>([]);
    const [isActive, setIsActive] = useState<boolean>(false);
    const db = getDatabase();

    useEffect(() => {
        const timersRef = ref(db, 'stopwatch/' + currentUser?.uid);
        return onValue(timersRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data === null) return
                setPreviousEvent(data.previousEvent)
                setIsActive(data.previousEvent.event !== "pause" && data.previousEvent.event !== "clear")
                setCurrentCount(data.currentCount ?? 0)
                setCurrentLapCount(data.currentLapCount ?? 0)
                setTotalLapTimes(data.totalLapTimes?.length > 0 ? data.totalLapTimes : [])
                setLapDurations(data.lapDurations?.length > 0 ? data.lapDurations : [])
            } else {
                update(ref(db, 'stopwatch/' + currentUser?.uid), {
                    previousEvent: { time: 0, event: "clear" },
                    currentCount: 0,
                    currentLapCount: 0,
                    totalLapTimes: [],
                    lapDurations: [],
                    isActive: false
                })
            }
        });
    }, [currentUser, db])




    const dbActive = useCallback((event: event) => {
        update(ref(db, 'stopwatch/' + currentUser?.uid), {
            previousEvent: event,
        })
    }, [currentUser, db])

    const dbPause = useCallback((event: event, currentCount: number, currentLapCount: number) => {
        update(ref(db, 'stopwatch/' + currentUser?.uid), {
            previousEvent: event,
            currentCount: currentCount,
            currentLapCount: currentLapCount,
        })
    }, [currentUser, db])

    const dbLap = useCallback((event: event, currentCount: number, currentLapCount: number, totalLapTimes: number[], lapDurations: number[]) => {
        update(ref(db, 'stopwatch/' + currentUser?.uid), {
            previousEvent: event,
            currentCount: currentCount,
            currentLapCount: currentLapCount,
            totalLapTimes: totalLapTimes,
            lapDurations: lapDurations,
        })
    }, [currentUser, db])

    const dbClear = useCallback(() => {
        update(ref(db, 'stopwatch/' + currentUser?.uid), {
            previousEvent: { time: 0, event: "clear" },
            currentCount: 0,
            currentLapCount: 0,
            totalLapTimes: [],
            lapDurations: [],
        })
    }, [currentUser, db])

    const handleControlEvent = useCallback((event: event) => {
        if (previousEvent.event === "active" && event.event === "active") return
        if (previousEvent.event === "pause" && event.event === "pause") return
        if (previousEvent.event === "clear" && event.event === "clear") return
        let currentCountHolder;
        let currentLapCountHolder;
        let lapDurationHolder;
        let lapDurationsHolder;
        let totalLapTimesHolder;
        switch (event.event) {
            case "active":
                dbActive(event)
                break;
            case "pause":
                currentCountHolder = event.time - previousEvent.time + currentCount
                currentLapCountHolder = event.time - previousEvent.time + currentLapCount
                dbPause(event, currentCountHolder, currentLapCountHolder)
                setCurrentCount(currentCountHolder)
                setCurrentLapCount(currentLapCountHolder)
                break

            case "lap":
                currentCountHolder = event.time - previousEvent.time + currentCount
                lapDurationHolder = event.time - previousEvent.time + currentLapCount
                lapDurationsHolder = [lapDurationHolder].concat(lapDurations)
                totalLapTimesHolder = [currentCountHolder].concat(totalLapTimes)
                currentLapCountHolder = 0
                dbLap(event, currentCountHolder, currentLapCountHolder, totalLapTimesHolder, lapDurationsHolder)
                setCurrentCount(currentCountHolder)
                setCurrentLapCount(currentLapCountHolder)
                setTotalLapTimes(totalLapTimesHolder)
                setLapDurations(lapDurationsHolder)
                break
            case "clear":
                dbClear()
                setCurrentCount(0)
                setCurrentLapCount(0)
                setTotalLapTimes([])
                setLapDurations([])
                break
        }
        setPreviousEvent(event)
        setIsActive(event.event !== "pause" && event.event !== "clear")
    }, [dbLap, dbActive, dbClear, dbPause, previousEvent, currentCount, currentLapCount, lapDurations, totalLapTimes]);

    const value = {
        handleControlEvent,
        previousEvent,
        currentCount,
        currentLapCount,
        totalLapTimes,
        lapDurations,
        isActive
    }

    return (
        <StopwatchContext.Provider value={value}>
            {children}
        </StopwatchContext.Provider>
    )
}