type Props = {
    className: string
}

export default function PlaySVG({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`${className} stroke-white dark:stroke-neutral-800`} strokeWidth={.8}>
            <path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z" />
        </svg>
    );
}