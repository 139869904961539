type Props = {
    className: string
}

export default function PauseSVG({ className }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`${className} stroke-white dark:stroke-neutral-800`} strokeWidth={2.2}>
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
        </svg>
    );
}