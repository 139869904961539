import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../common/styled/Button";
import { PageCard } from "../../common/styled/decorators/PageCard";
import { useAuth } from "../../contexts/AuthContext";

export const EmailVerification = () => {
  const { logOut: logout, sendVerfication } = useAuth()
  const [sent, setSent] = useState(false)
  const navigate = useNavigate()
  let location: any = useLocation();
  let from = location.state?.from || "/";

  useEffect(() => {
    sendVerfication()
  }, [sendVerfication])

  const handleResend = useCallback(async () => {
    setSent(true)
    sendVerfication()
  }, [sendVerfication])

  const handleBack = useCallback(() => {
    logout()
    navigate("/login", { state: { from: from }, replace: true });
  }, [logout, navigate, from])

  useEffect(() => {
    let clear: NodeJS.Timeout
    if (sent) {
      clear = setTimeout(() => setSent(false), 10000)
    }
    return () => {
      clearTimeout(clear);
    };
  }, [sent])

  return (
    <PageCard children={<>
      <h1 className="font-bold text-xl w-full">Email Verification</h1>
      <div className="flex justify-start w-full">
        <p> A verification email has been send to the email address you provided. Please verify your email address. </p>
      </div>
      <Button disabled={sent} onClick={handleResend} text="Resend Verification" />
      <Button onClick={handleBack} text="Email Confirmed" />
    </>} />)
}