import {WebSVG} from "../../common/svg/WebSVG"
import {Link, useLocation} from "react-router-dom"
import {projectCategories} from "../../shared/consts"
import {MobileSVG} from "../../common/svg/MobileSVG"

type Props = {
  category: string,
  text: string,
  link: string
}

export const ProjectNavRow = ({category, text, link}: Props) => {
  let pathname = useLocation().pathname

  const isActive = pathname === "/projects/" + category
  const className = isActive ? "p-1 h-10 w-10 fill-white dark:fill-black" : "p-1 h-10 w-10 fill-black dark:fill-white"
  let holder;
  if (category === projectCategories.MOBILE) {
    holder = <MobileSVG className={className} />
  } else {
    holder = <WebSVG className={className} />
  }
  return (
    <Link to={link}>
      <div className={`flex rounded-full md:rounded-none w-full border 
        ${isActive ?
          "bg-black border-black dark:bg-neutral-100 dark:border-opacity-25"
          :
          "bg-white dark:border-neutral-800 dark:bg-neutral-900 dark:text-white dark:hover:border-neutral-400 dark:border-opacity-50"}`
      }>
        {holder}
        <div className={`hidden md:flex justify-center items-center w-24 ${isActive && "text-white dark:text-black"}`}>{text}</div>
      </div>
    </Link>
  )
}
