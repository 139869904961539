import {Dispatch, useEffect, useReducer, useState} from "react"
import {useBooking} from "../context/BookingContext"

enum ActionType {
    UPDATE_ITEM_NAME = "ADD_AIRPORT",
    ADD_ITEM = "ADD_ITEM",
    REMOVE_ITEM = "REMOVE_ITEM",
}

type Action = | {type: ActionType.ADD_ITEM, payload: {text: string, tab: Tabs}}
    | {type: ActionType.REMOVE_ITEM, payload: {index: number, tab: Tabs}}
    | {type: ActionType.UPDATE_ITEM_NAME, payload: {newName: string, tab: Tabs, index: number}}


export const reducer = (state: SettingsState, action: Action) => {
    switch (action.type) {
        case ActionType.ADD_ITEM:
            return {
                ...state,
                [action.payload.tab]: [action.payload.text, ...state[action.payload.tab],]
            }
        case ActionType.REMOVE_ITEM:
            return {
                ...state,
                [action.payload.tab]: state[action.payload.tab].filter((_, index) => index !== action.payload.index)
            }
        case ActionType.UPDATE_ITEM_NAME:
            return {
                ...state,
                [action.payload.tab]: state[action.payload.tab].map((item, index) => index === action.payload.index ? action.payload.newName : item)
            }
        default:
            return state
    }
}

type SettingsState = {
    airports: string[],
    hotels: string[],
    seatClasses: string[],
    airlines: string[],
    names: string[],
    extras: string[],
}

type Tabs = 'airports' | 'hotels' | 'seatClasses' | 'airlines' | 'names' | 'extras';

export default function Settings() {
    const {state: {airlines, airports, hotels, names, seatClasses, extras}, updateBooking} = useBooking()

    const [state, dispatch] = useReducer(reducer, {
        airlines: airlines,
        airports: airports,
        hotels: hotels,
        names: names,
        seatClasses: seatClasses,
        extras: extras,
    })
    const [activeTab, setActiveTab] = useState<Tabs>('airports');
    const [saveState, setSaveState] = useState<'saved' | 'saving' | 'error' | 'idle'>('idle')
    const [inputAddItem, setInputAddItem] = useState('')

    async function handleSave() {
        setSaveState('saving')
        //filter out empty strings
        let airlines = state.airlines.filter((airline) => airline.length > 0) ?? []
        let airports = state.airports.filter((airport) => airport.length > 0) ?? []
        let hotels = state.hotels.filter((hotel) => hotel.length > 0) ?? []
        let names = state.names.filter((name) => name.length > 0) ?? []
        let seatClasses = state.seatClasses.filter((seatClass) => seatClass.length > 0) ?? []
        let extras = state.extras.filter((extra) => extra.length > 0) ?? []
        await updateBooking({
            airlines: airlines.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})),
            airports: airports.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})),
            hotels: hotels.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})),
            names: names.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})),
            seatClasses,
            extras: extras.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})),
        })
        setSaveState('saved')
    }

    useEffect(() => {
        if (saveState === 'saved') {
            setTimeout(() => setSaveState('idle'), 1000)
        }
    }, [saveState])

    function handleAddItem() {
        dispatch({type: ActionType.ADD_ITEM, payload: {text: inputAddItem, tab: activeTab}})
        setInputAddItem('')
    }

    return (<div className="grid grid-cols-[80px,_1fr] md:grid-cols-[96px,_1fr] gap-x-2 w-full">
        <div className="flex flex-col gap-2 w-full">
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'airports' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('airports')}>Airports</button>
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'airlines' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('airlines')}>Airlines</button>
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'seatClasses' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('seatClasses')}>Seat Classes</button>
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'names' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('names')}>Names</button>
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'hotels' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('hotels')}>Hotels</button>
            <button className={`w-full text-sm font-medium h-8 border border-black dark:border-neutral-50 dark:border-opacity-30 rounded whitespace-nowrap  ${activeTab === 'extras' ? "bg-neutral-200  dark:bg-neutral-800" : "dark:bg-neutral-600"}`} onClick={() => setActiveTab('extras')}>Extras</button>

        </div>
        <div className="flex flex-col gap-2 w-full dark:bg-neutral-800 bg-neutral-200 border border-black border-opacity-50 shadow-md rounded p-2 dark:border-neutral-50 dark:border-opacity-30">
            <div className="flex">
                <input className="grow px-2 dark:bg-neutral-700" value={inputAddItem} onChange={(e) => {setInputAddItem(e.target.value)}} />
                <button onClick={handleAddItem} className="w-6 h-6 dark:fill-neutral-50 fill-black ml-2" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                        <path d="M450 776h60V606h170v-60H510V376h-60v170H280v60h170v170ZM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600V276H180v600Zm0-600v600-600Z" />
                    </svg>
                </button>
            </div>
            <div className="h-full w-full overflow-y-scroll">
                {activeTab === 'airports' && state.airports.map((airport, i) => <Row key={i} text={airport} index={i} dispatch={dispatch} activeTab={activeTab} />)}
                {activeTab === 'hotels' && state.hotels.map((hotel, i) => <Row key={i} text={hotel} index={i} dispatch={dispatch} activeTab={activeTab} />)}
                {activeTab === 'seatClasses' && state.seatClasses.map((seatClass, i) => <Row key={i} text={seatClass} index={i} dispatch={dispatch} activeTab={activeTab} />)}
                {activeTab === 'airlines' && state.airlines.map((airline, i) => <Row key={i} text={airline} index={i} dispatch={dispatch} activeTab={activeTab} />)}
                {activeTab === 'names' && state.names.map((name, i) => <Row key={i} text={name} index={i} dispatch={dispatch} activeTab={activeTab} />)}
                {activeTab === 'extras' && state.extras.map((extra, i) => <Row key={i} text={extra} index={i} dispatch={dispatch} activeTab={activeTab} />)}
            </div>
            <button onClick={handleSave} className={`border border-black dark:border-neutral-50 dark:border-opacity-30 rounded p-1 hover:opacity-80 bg-neutral-100 dark:bg-neutral-600`}>
                {
                    saveState === 'idle' && <span>Save</span>
                }
                {
                    saveState === 'saving' && <span>Saving...</span>
                }
                {
                    saveState === 'saved' && <span>Saved</span>
                }
            </button>
        </div>
    </div>
    )
}

type Props = {
    text: string,
    dispatch: Dispatch<Action>,
    index: number,
    activeTab: Tabs,
}

const Row = ({text, dispatch, activeTab, index}: Props) => {
    return (
        <div className={`flex w-full h-8 items-center gap-2`}>
            <input className="px-2 grow dark:bg-neutral-700" value={text} onChange={(e) => dispatch({type: ActionType.UPDATE_ITEM_NAME, payload: {newName: e.target.value, tab: activeTab, index}})} />
            <button onClick={() => dispatch({type: ActionType.REMOVE_ITEM, payload: {tab: activeTab, index}})} className="w-6 h-6">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                    <path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z" />
                </svg>
            </button>
        </div>
    )
}
