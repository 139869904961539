import React from 'react'
import {lapDisplayFormat} from '../utils/utils'
import {useStopwatch} from '../contexts/StopwatchContext';

type Props = {
    lapDisplay: string
    totalLapDisplay: string
    format: string
}

export default function LapDisplay({lapDisplay, totalLapDisplay, format}: Props) {
    const {totalLapTimes, lapDurations} = useStopwatch()

    const currentLap = lapDurations?.map((time, key) => {
        return <div key={key}>
            {lapDisplayFormat(time, format)}
        </div>
    })

    const total = totalLapTimes?.map((time, key) => {
        return <div key={key}>
            {lapDisplayFormat(time, format)}
        </div>
    })

    return (
        <div className="flex gap-12 md:gap-2  
            md:w-64 md:h-52 max-h-44 md:max-h-52 
            mt-4 px-8 md:px-2 
            md:bg-neutral-100 md:dark:bg-neutral-800
            md:border border-black md:rounded-md 
            overflow-y-scroll">
            {lapDurations?.length > 0 && <>
                <div className="w-full">
                    <div >{lapDisplay}</div>
                    <div>{currentLap}</div>
                </div>
                <div className="w-full">
                    <div>{totalLapDisplay}</div>
                    <div>{total}</div>
                </div>
            </>}
        </div>
    )
}
