import React, {useCallback, useContext, useEffect, useState} from 'react'
import {auth} from "../firebase";
import {
  createUserWithEmailAndPassword, signInWithEmailAndPassword,
  GoogleAuthProvider, signOut, sendPasswordResetEmail,
  signInWithPopup, User, sendEmailVerification, deleteUser, updateEmail, updatePassword,
} from "firebase/auth";
import {FirebaseError} from 'firebase/app';


type Auth = {
  currentUser: User | null | undefined;
  emailVerified: boolean
  logOut: () => void
  signUp: (email: string, password: string) => Promise<string>
  signIn: (email: string, password: string) => Promise<string>
  changeEmail: (email: string) => Promise<string>;
  changePassword: (password: string) => Promise<string>
  deleteAccount: () => Promise<string>
  sendVerfication: () => Promise<string>
  passwordReset: (email: string) => Promise<string>
  googleLogin: () => Promise<string>
  reauthWithEmailAndPassword: (email: string, password: string) => Promise<string>
  reauthWithGoogle: (email: string, password: string) => Promise<string>
}

const AuthContext = React.createContext<Auth>({
  currentUser: null,
  emailVerified: false,
  signUp: (email: string, password: string) => new Promise(() => ""),
  signIn: (email: string, password: string) => new Promise(() => ""),
  deleteAccount: () => new Promise(() => ""),
  changeEmail: (email: string) => new Promise(() => ""),
  changePassword: (password: string) => new Promise(() => ""),
  reauthWithEmailAndPassword: (email: string, password: string) => new Promise(() => ""),
  reauthWithGoogle: (email: string, password: string) => new Promise(() => ""),
  logOut: () => signOut(auth),
  sendVerfication: () => new Promise(() => ""),
  passwordReset: (email: string) => new Promise(() => ""),
  googleLogin: () => new Promise(() => ""),
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [emailVerified, setEmailVerified] = useState(false)
  const [loading, setLoading] = useState(true)
  const [transition, setTransition] = useState(true)
  const TRANSITION_TIME = 0

  const simplifyError = useCallback((error: FirebaseError) => {
    let errorList: any = {
      "auth/requires-recent-login": AUTH_REAUTH,
      "auth/network-request-failed": "Network error.",
      "auth/timeout": "Network timeout.",
      "auth/user-not-found": "Email not found.",
      "auth/wrong-password": "Email or password didn't match.",
      "auth/invalid-email": "Invalid email.",
      "auth/email-already-in-use": "Email already in use.",
      "auth/credential-already-in-use": "Credential already in use.",
      "auth/weak-password": "Your password is too weak.",
      "auth/popup-closed-by-user": AUTH_NO_ERROR,
      "auth/account-exists-with-different-credential": "Account already exists.",
      "auth/invalid-phone-number": "Invalid phone number.",
    }
    return (errorList[error.code]) ? errorList[error.code] : "Oops! Something went wrong!"
  }, [])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      setEmailVerified(user === null ? false : user.emailVerified)
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    let timeout: any
    if (!loading && transition) {
      timeout = setTimeout(() => setTransition(false), TRANSITION_TIME)
    }
    return () => {clearTimeout(timeout)}
  }, [transition, setTransition, loading, setLoading])

  const signUp = async (email: string, password: string) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const signIn = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const logOut = () => signOut(auth)

  const passwordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const googleLogin = async () => {
    try {
      await signInWithPopup(auth, new GoogleAuthProvider())
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const sendVerfication = async () => {
    if (auth.currentUser === null) return ""
    try {
      await sendEmailVerification(auth.currentUser)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const deleteAccount = async () => {
    if (auth.currentUser === null) return ""
    try {
      await deleteUser(auth.currentUser)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const changeEmail = async (email: string) => {
    if (auth.currentUser === null) return ""
    try {
      await updateEmail(auth.currentUser, email)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const changePassword = async (password: string) => {
    if (auth.currentUser === null) return ""
    try {
      await updatePassword(auth.currentUser, password)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const reauthWithEmailAndPassword = async (email: string, password: string) => {
    await signOut(auth)
    try {
      await signIn(email, password)
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const reauthWithGoogle = async () => {
    await signOut(auth)
    try {
      await googleLogin()
      return AUTH_SUCCESS
    } catch (e: any) {
      return simplifyError(e)
    }
  }

  const value = {
    currentUser,
    emailVerified,
    signUp,
    signIn,
    deleteAccount,
    changeEmail,
    changePassword,
    logOut,
    sendVerfication,
    passwordReset,
    googleLogin,
    reauthWithEmailAndPassword,
    reauthWithGoogle
  }

  //      {transition ? <Spinner /> : children}
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export const AUTH_SUCCESS = "success"
export const AUTH_REAUTH = "reauth"
export const AUTH_NO_ERROR = ""