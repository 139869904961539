import {ReactChild} from "react"

type Props = {
    disabled?: boolean,
    children?: ReactChild
    className?: string
    text?: string
    onClick?: () => void
}

export const Button = (({disabled, children, text, onClick, className}: Props) => {
    return (                //relative hover:bg-slate-100 mb-2
        <button className={`relative w-full h-12 
            border rounded-md hover:border-transparent 
            bg-white dark:bg-neutral-800
            border-black dark:border-neutral-50 
            border-opacity-25 dark:border-opacity-25
            hover:bg-neutral-100 dark:hover:bg-neutral-700 disabled:opacity-75 ${className}`}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
            <div className="flex justify-center align-middle p-3">
                <p>{text}</p>
            </div>
        </button>
    )
})