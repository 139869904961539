import { useState } from "react"
import { AUTH_SUCCESS, useAuth } from "../../contexts/AuthContext"
import { Link, useLocation } from "react-router-dom"
import { ErrorSVG } from "../../common/svg/ErrorSVG"
import { CheckMarkSVG } from "../../common/svg/CheckMarkSVG"
import { Form } from "../../common/styled/Form"
import { Input } from "../../common/styled/Input"
import { SubmitButton } from "../../common/styled/SubmitButton"
import { Button } from "../../common/styled/Button"
import { Divider } from "../../common/styled/Divider"
import { PageCard } from "../../common/styled/decorators/PageCard"

export const PasswordRecovery = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const { passwordReset } = useAuth()
    const [error, setError] = useState("")
    const [sent, setSent] = useState("")
    let location: any = useLocation();
    let from = location.state?.from || "/";

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError("")
        setSent("")
        if (email.length === 0) {
            setError("Please fill in your email.")
            return
        }

        setLoading(true)
        const result = await passwordReset(email)
        if (result === AUTH_SUCCESS) {
            setSent('Password reset sent! Please check your email.')
        } else {
            setError(result)
            setLoading(false)
        }
    }

    return (
        <PageCard children={<>
            <h1 className="font-bold text-xl w-full">Password Recovery</h1>
            <div className="flex justify-start w-full">
                <p> Enter your email to request a new password. </p>
            </div>
            <Form onSubmit={handleSubmit} children={<>
                <div className="w-full">
                    <label>Email</label>
                    <Input setState={setEmail} state={email} type="email" placeholder="Email" />
                </div>

                <div className="flex justify-between w-full">
                    {error.length > 0 &&
                        <div className="flex justify-start w-full">
                            <ErrorSVG />
                            <div className="ml-1">{error}</div>
                        </div>
                    }
                    {
                        (sent && !error) &&
                        <div className="flex justify-start w-full">
                            <CheckMarkSVG />
                            <p className="ml-1">Message Sent.</p>
                        </div>
                    }
                </div>
                <SubmitButton
                    disabled={loading}
                    text="Request Password"
                />
            </>} />
            <Divider text="or" />
            <div className="flex flex-col w-full gap-4">
                <Link to="/signup" state={{ from: from }} replace><Button text="Create New Account" /></Link>
                <Link to="/login" state={{ from: from }} replace><Button text="Return to Login" /></Link>
            </div>
        </>} />)
}