type Props = {
    state: string,
    setState: React.Dispatch<React.SetStateAction<string>>,
    type: string
    placeholder?: string
    className?: string
}

export const Input = (({ placeholder, setState, state, type, className }: Props) => {
    return (
        <input className={`w-full p-1 border
        bg-white dark:bg-neutral-900
        border-black dark:border-neutral-50 
        border-opacity-25 dark:border-opacity-25 ${className}`}
            onChange={(e) => setState(e.target.value)}
            value={state}
            type={type}
            placeholder={placeholder}

        />
    )
})