import { useState } from "react";
import { AUTH_SUCCESS, useAuth } from "../../contexts/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorSVG } from "../../common/svg/ErrorSVG";
import { Input } from "../../common/styled/Input";
import { SubmitButton } from "../../common/styled/SubmitButton";
import { Form } from "../../common/styled/Form";
import { PageCard } from "../../common/styled/decorators/PageCard";
import { Divider } from "../../common/styled/Divider";
import { GoogleButton } from "./GoogleButton";

export const Signup = () => {

    let navigate = useNavigate();
    let location: any = useLocation();
    let from = location.state?.from || "/";

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { signUp } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { googleLogin } = useAuth()
    const [passwordShown, setPasswordShown] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (password === "" || email === "") {
            setError("Please fill in email and passwords.")
            return
        }
        setError("")
        setLoading(true)
        const result = await signUp(email, password);
        if (result === AUTH_SUCCESS) {
            navigate(from, { replace: true });
        } else {
            setError(result)
            setLoading(false)
        }
    }

    const googleClick = async () => {
        setLoading(true)
        const result = await googleLogin()
        if (result === AUTH_SUCCESS) {
            navigate(from, { replace: true });
        } else {
            setLoading(false)
        }
    }

    return (
        <PageCard children={<>
            <h1 className="font-bold text-xl w-full">Welcome to Company Hill</h1>
            <div className="flex justify-start w-full">
                <p>Already have an account?</p>
                <Link to="/login" state={{ from: from }} replace className="text-blue-600 ml-1">Log in</Link>
            </div>
            <Form onSubmit={handleSubmit} children={<>
                <div className="w-full">
                    <label>Email</label>
                    <Input setState={setEmail} state={email} type="email" placeholder="Email" />
                </div>
                <div className="w-full">
                    <label>Password</label>
                    <Input setState={setPassword} state={password} type={passwordShown ? 'text' : "password"} placeholder="Password" />
                    <div className="flex items-center">
                        <input type="checkbox" className="m-2 mr-2" checked={passwordShown} onChange={() => setPasswordShown(!passwordShown)} />
                        <div className="text-sm">Show Password</div>
                    </div>
                </div>
                <div className="flex justify-between w-full">
                    {error.length > 0 &&
                        <div className="flex justify-start w-full">
                            <ErrorSVG />
                            <div className="ml-1">{error}</div>
                        </div>
                    }
                </div>
                <SubmitButton
                    disabled={loading}
                    text="Create Account"
                />
                <Divider text="or" />
                <GoogleButton loading={loading} googleClick={googleClick} />
            </>} />
        </>} />)
}