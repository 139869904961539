import {useState} from 'react';
import {BookingProvider} from './context/BookingContext';
import TopNav from './components/TopNav';
import {Tabs} from './shared/types';
import Settings from './views/Settings';
import Hotel from './views/Hotel';
import Flight from './views/Flight';

export function BookingLayout() {
    const [activeTab, setActiveTab] = useState<Tabs>('flight');
    return (
        <BookingProvider>
            <div className='flex place-content-center px-2 md:px-0 mx-auto h-fit w-full sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-1/3 dark:text-neutral-50 p-2 dark:fill-neutral-50'>
                <div className='flex flex-col gap-2 justify-center items-center h-fit w-full'>
                    <TopNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    {
                        activeTab === 'settings' && <Settings />
                    }
                    {
                        activeTab === 'hotel' && <Hotel />
                    }
                    {
                        activeTab === 'flight' && <Flight />
                    }
                </div>
            </div>
        </BookingProvider>
    )
}
