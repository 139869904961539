type Props = {
    text?: string
}

export const Divider = (({ text }: Props) => {
    return (
        <div className="flex w-full p-4">
            <span className="w-full border-b opacity-50 dark:opacity-25 m-2" />
            {text && <>
                <p className="mx-8">{text}</p>
                <span className="w-full border-b opacity-50 dark:opacity-25 m-2" />
            </>}
        </div>
    )
})