import {ReactNode} from "react"
import {Avatar} from "../Avatar"
import {ProfileOptions} from "../modals/ProfileOptions"

type Props = {
    openModal: (modal: ReactNode) => void
    closeModal: () => void
}

export const AvatarButton = ({openModal, closeModal}: Props) => {
    return (<button onClick={() => openModal(<ProfileOptions closeModal={closeModal} />)}
        className="absolute right-0 top-0 border rounded-full h-11 w-11 md:h-12 md:w-12 mr-3 mt-8 md:mr-4 border-black dark:border-0">
        <Avatar className="h-full w-full rounded-full" />
    </button>)
}
