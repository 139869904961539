import { ReactNode } from "react"

type Props = {
    className?: string
    children: ReactNode
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}
export const Form = (({ children, onSubmit, className }: Props) => {
    return (
        <form onSubmit={(event) => onSubmit(event)} className={`${className} flex flex-col items-center space-y-2 w-full`}>
            {children}
        </form>
    )
})