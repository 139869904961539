import {Tabs} from "../shared/types";

type Props = {
    activeTab: Tabs,
    setActiveTab: (tab: Tabs) => void
};
export default function TopNav({activeTab, setActiveTab}: Props) {


    return (
        <div className="flex justify-evenly items-center w-full h-fit dark:bg-neutral-800 bg-neutral-200 border border-black border-opacity-50 dark:border-neutral-50 dark:border-opacity-30 shadow-md rounded p-2">
            <button
                className={`rounded-full p-1 ${activeTab === 'flight' && 'bg-neutral-300 text-white dark:bg-neutral-700'}`}
                onClick={() => setActiveTab('flight')}>
                <svg className={`w-8 h-8`} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
                    <path d="m348 731 368-101q17-5 25-16t3-28q-5-17-18-23.5t-28.796-2.367L594 587 428 425l-52 12 103 181-111 31-52-43-29 10 61 115Zm472 165H140q-24.75 0-42.375-17.625T80 836V683q37-8 61.5-37.5T166 576q0-40-24.5-70T80 469V316q0-24.75 17.625-42.375T140 256h680q24.75 0 42.375 17.625T880 316v520q0 24.75-17.625 42.375T820 896Zm0-60V316H140v109q39 26 62.5 65t23.5 86q0 47-23.5 86T140 727v109h680ZM480 576Z" />            </svg>
            </button>
            <button className={`rounded-full p-1 ${activeTab === 'hotel' && 'bg-neutral-300 text-white dark:bg-neutral-700'}`}
                onClick={() => setActiveTab('hotel')}        >
                <svg className={`w-8 h-8`} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
                    <path d="M40 856V271h60v394h353V356h322q59.812 0 102.406 42.594Q920 441.188 920 501v355h-60V725H100v131H40Zm230-249q-45 0-75.5-30.5T164 501q0-45 30.5-75.5T270 395q45 0 75.5 30.5T376 501q0 45-30.5 75.5T270 607Zm243 58h347V501q0-35.062-24.969-60.031T775 416H513v249ZM270 547q19 0 32.5-13.5T316 501q0-19-13.5-32.5T270 455q-19 0-32.5 13.5T224 501q0 19 13.5 32.5T270 547Zm0-46Zm243-85v249-249Z" />
                </svg>
            </button>
            <button className={`rounded-full p-1 ${activeTab === 'settings' && 'bg-neutral-300 text-white dark:bg-neutral-700'}`}
                onClick={() => setActiveTab('settings')} >
                <svg className={`w-8 h-8`} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
                    <path d="m388 976-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535L80 456l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669 346l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592 850l-20 126H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-60q-29 0-49.5-20.5T410 576q0-29 20.5-49.5T480 506q29 0 49.5 20.5T550 576q0 29-20.5 49.5T480 646Zm0-70Zm-44 340h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715 576q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538 348l-14-112h-88l-14 112q-34 7-63.5 24T306 414l-106-46-40 72 94 69q-4 17-6.5 33.5T245 576q0 17 2.5 33.5T254 643l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Z" />
                </svg>
            </button>
        </div>
    )
}
