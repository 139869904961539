import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Footer } from '../../common/layouts/Footer';
import { projectCategories } from '../../shared/consts';
import { ProjectNav } from './ProjectNav';

export const Projects = () => {

  let pathname = useLocation().pathname
  if (pathname === "/projects") return <Navigate to={projectCategories.WEB} replace />
  document.getElementById("projectContainer")?.scrollTo(0, 0)

  return (
    <div id="projectContainer" className="flex flex-col relative h-full w-full mx-auto">
      <ProjectNav />
      <div className="flex-1">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}