import {useEffect, useState} from 'react';
import {Button} from '../../../../common/styled/Button';
import {Input} from '../../../../common/styled/Input';
import {Textarea} from '../../../../common/styled/Textarea';
import {WebApp} from '../../../../common/styled/WebApp';
import HelpSVG from './svgs/HelpSVG';

export const SubstringSequencer = () => {
    const [copyButton, setCopyButton] = useState<string>('Copy Results');
    const [baseText, setBaseText] = useState('');
    const [newText, setNewText] = useState('');
    const [substring, setSubstring] = useState('');
    const [period, setPeriod] = useState(true);
    const [space, setSpace] = useState(false);

    useEffect(() => {
        if (copyButton !== 'Copy Results') {
            setTimeout(() => {
                switch (copyButton) {
                    case "Copied!": setCopyButton("Copy Results"); break;
                }
            }, 1000)
        }
    }, [copyButton])


    function periodChange() {
        setPeriod(!period)
    }

    function spaceChange() {
        setSpace(!space)
    }

    function substringIteration() {
        let text = baseText;
        let appendString = period ? "." : " "
        appendString = space ? appendString + " " : appendString + ""
        for (let i = 1; text.includes(substring) && substring.length > 0; i++) {
            text = text.replace(substring, i.toString() + appendString);
        }
        setNewText(text)
    }

    function copy() {
        navigator.clipboard.writeText(newText);
        setCopyButton("Copied!")
    }


    function help() {
        setBaseText(
            `xx Type "x" twice into the substring input below
xx and press the 
xx Go! button to see an
xx example
xx of what this sequencer
xx can
xx do!!`
        );
    }
    //<div className="flex md:mt-2 mx-auto md:w-3/4 h-full">
    const topNav = <button onClick={help} >
        <HelpSVG className="h-8 w-8" bgColor='#5683B7' />
    </button>

    return (
        <WebApp title={'Substring Sequencer'} topNav={topNav} children={
            <div className="flex flex-col h-full w-full">
                <Textarea
                    placeholder="Paste Here"
                    state={baseText}
                    setState={setBaseText}
                    className="flex-1 resize-none"
                />
                <div className="flex justify-start w-full my-2 flex-wrap space-y-2 ">
                    <Input
                        state={substring}
                        setState={setSubstring}
                        type='text'
                        placeholder="Substring e.g. xx"
                        className="w-28 h-10 my-auto "
                    />
                    <div className="flex">
                        <div className="flex flex-col justify-center items-center h-6">
                            <p className="font-hairline text-m px-2">+Period</p>
                            <input type="checkbox" checked={period} onChange={periodChange} />
                        </div>
                        <div className="flex flex-col justify-center items-center h-6">
                            <p className="font-hairline text-m px-2">+Space</p>
                            <input type="checkbox" checked={space} onChange={spaceChange} />
                        </div>
                    </div>
                    <div>
                        <Button
                            onClick={substringIteration}
                            className="w-32 bg-green-300 rounded-full mx-2"
                            text='Sequence'
                        />
                        <Button
                            onClick={copy}
                            className="w-32 bg-red-300 rounded-full mx-2"
                            text={copyButton}
                        />
                    </div>

                </div>
                <Textarea
                    placeholder="Results"
                    readOnly
                    state={newText}
                    setState={setNewText}
                    className="flex-1 resize-none"
                />
            </div>} />
    );
}

