import { useState } from "react"
import { AUTH_REAUTH, AUTH_SUCCESS, useAuth } from "../../contexts/AuthContext"
import { useLocation, useNavigate } from "react-router-dom"
import { ErrorSVG } from "../svg/ErrorSVG"
import { CheckMarkSVG } from "../svg/CheckMarkSVG"
import { Form } from "../styled/Form"
import { Input } from "../styled/Input"
import { CloseButton } from "./CloseButton"
import { Button } from "../styled/Button"
import { ModalCard } from "../styled/decorators/ModalCard"

type Props = {
    closeModal: () => void
}
export const DeleteAccount = ({ closeModal }: Props) => {
    const location: any = useLocation();
    const from = location.state?.from || "/";
    const navigate = useNavigate()

    const [deleteCheck, setDeleteCheck] = useState("")
    const [loading, setLoading] = useState(false)
    const { deleteAccount } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setMessage("")
        if (deleteCheck !== "delete") {
            setError("Type delete to delete account.")
            return
        }
        setError("")
        setLoading(true)
        const result = await deleteAccount();
        if (result === AUTH_SUCCESS) {
            navigate("/", { replace: true });
        } else if (result === AUTH_REAUTH) {
            closeModal()
            navigate("/reauthentication", { state: { modal: "deleteAccount", from: from } })
            return
        } else {
            setError(result)
        }
        setLoading(false)
    }

    return (
        <>
            <div className="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-black opacity-80 z-40" />
            <div onClick={closeModal} className="fixed flex justify-center items-center top-0 left-0  w-full h-full z-50">
                <ModalCard children={
                    <>
                        <CloseButton closeModal={closeModal} />
                        <div className="flex flex-col gap-2">
                            <h1 className="font-bold text-xl w-full">Delete Account</h1>
                            <Form onSubmit={handleSubmit} children={<>
                                <div className="w-full">
                                    <label>Type the word <strong>delete</strong> to delete account.</label>
                                    <Input setState={setDeleteCheck} state={deleteCheck} type={""} />
                                </div>
                                <div className="flex justify-between w-full">
                                    {error.length > 0 &&
                                        <div className="flex justify-start w-full">
                                            <ErrorSVG />
                                            <div className="ml-1">{error}</div>
                                        </div>

                                    }
                                    {
                                        (message && !error) &&
                                        <div className="flex justify-start w-full">
                                            <CheckMarkSVG />
                                            <p className="ml-1">Password Changed</p>
                                        </div>
                                    }

                                </div>
                                <Button disabled={loading || deleteCheck !== "delete"} text="Delete Account" />
                            </>} />
                        </div>
                    </>} />
            </div>
        </>
    )
}