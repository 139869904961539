type Props = {
    className?: string;
}

export const CatSVG = ({ className }: Props) => {
    return (
        <svg className={className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">

            <g id="Layer_1">
                <g>
                    <g>
                        <path d="M59.561,32.209c0.891-0.78,2.459-0.941,3.573-1.137c1.72-0.303,3.517-0.264,5.243-0.065
				c-0.432-0.248-0.863-0.495-1.295-0.743c0.443,0.941-0.094,2.613-1.076,3c-1.779,0.699-1.006,3.601,0.797,2.893
				c1.696-0.667,2.67-1.867,3.196-3.601c0.37-1.217,0.418-3.592-0.865-4.343c-1.694-0.992-4.962-0.299-6.798-0.033
				c-1.717,0.249-3.567,0.744-4.896,1.909C55.981,31.365,58.112,33.478,59.561,32.209L59.561,32.209z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M35.946,29.726c-0.586-3.143-0.594-6.394,1.238-9.139c1.077-1.614-1.522-3.114-2.591-1.514
				c-2.284,3.421-2.269,7.54-1.54,11.45C33.408,32.424,36.299,31.617,35.946,29.726L35.946,29.726z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M35.884,21.05c2.495,0.979,4.792,2.606,6.056,5.018c0.898,1.714,3.488,0.198,2.591-1.514
				c-1.637-3.124-4.642-5.139-7.849-6.396C34.878,17.45,34.104,20.353,35.884,21.05L35.884,21.05z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M38.625,49.75C38.688,57.843,39.437,65.907,39.5,74c0.028,3.584,0.255,7.129,0.686,10.688
				c0.237,1.955,0.228,5.08,1.767,6.619c2.118,2.117,4.91,0.163,6.351-1.73c4.292-5.64,1.703-12.366,2.893-18.803
				c-0.964,0-1.928,0-2.893,0c1.276,5.239,0.479,11.071,0.225,16.417c-0.156,3.299,2.196,5.801,5.616,5.808
				c3.04,0.006,3.837-2.235,3.769-4.95C57.734,80.962,58.881,73.953,58,66.875c-0.235-1.894-3.238-1.917-3,0
				c0.536,4.305,0.401,8.558,0.165,12.879c-0.082,1.49,0.694,10.611-1.86,10.003c-2.992-0.713-1.545-8.694-1.452-10.661
				c0.146-3.09,0.077-6.108-0.656-9.119c-0.338-1.389-2.619-1.483-2.893,0c-0.711,3.847-0.263,7.645-0.29,11.529
				c-0.019,2.759-0.916,5.015-2.752,6.99c-1.531,1.647-2.42-6.914-2.465-7.371c-1-10.285-1.09-21.037-1.172-31.375
				C41.61,47.816,38.61,47.815,38.625,49.75L38.625,49.75z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M56.705,50.757c1.547,2.72,0.4,5.764,2.232,8.45c2.063,3.026,4.78,5.308,6.587,8.587
				c1.98,3.596,2.906,7.723,3.669,11.716c1.395,7.295-5.345,8.319-10.943,8.365c-1.934,0.016-1.936,3.016,0,3
				c4.453-0.036,8.823-0.336,12.346-3.314c2.781-2.351,1.814-7.156,1.237-10.177c-1.067-5.581-3.116-10.76-6.413-15.391
				c-1.648-2.316-4.604-3.885-4.947-7.058c-0.235-2.172-0.071-3.748-1.178-5.692C58.339,47.562,55.747,49.073,56.705,50.757
				L56.705,50.757z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M41.375,47.25c-9.782,1.161-10.417-10.283-4.886-15.799c1.371-1.367-0.75-3.488-2.121-2.121
				c-4.025,4.014-5.67,9.504-3.745,14.969c1.605,4.559,6.226,6.489,10.752,5.952C43.272,50.025,43.294,47.022,41.375,47.25
				L41.375,47.25z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M40.25,38c1.935,0,1.935-3,0-3S38.315,38,40.25,38L40.25,38z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M54.243,49.795c5.861,3.214,10.703-3.274,9.703-8.819c-0.343-1.902-3.233-1.093-2.893,0.798
				c0.535,2.97-1.938,7.273-5.297,5.431C54.06,46.274,52.547,48.865,54.243,49.795L54.243,49.795z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M52.25,41.625c1.935,0,1.935-3,0-3S50.315,41.625,52.25,41.625L52.25,41.625z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M43.382,27.977c4.078,0.066,11.483,0.683,13.823,4.572c0.995,1.655,3.59,0.147,2.59-1.514
				c-3.001-4.988-11.132-5.972-16.413-6.058C41.447,24.945,41.45,27.945,43.382,27.977L43.382,27.977z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M71.398,74.446c5.023-1.67,6.67-6.207,4.912-11.067c-0.447-1.236-1.304-2.334-1.985-3.441
				c-1.813-2.946,0.189-6.539,1.47-9.181c0.601-1.238,1.28-2.432,1.982-3.615c0.411-0.692,5.735-6.236,4.86-3.314
				c-1.185,3.956-8.266,9.585-6.391,13.976c1.434,3.354,3.019,5.755,2.188,9.57c-1.035,4.754-5.328,8.671-8.67,11.9
				c-1.394,1.347,0.73,3.466,2.121,2.121c5.484-5.301,11.434-11.79,9.338-19.982c-0.393-1.535-1.245-2.956-2.037-4.307
				c-0.883-1.506,2.346-5.296,3.118-6.471c1.556-2.37,3.813-5.495,3.576-8.494c-0.153-1.947-2.597-2.988-4.322-2.417
				c-3.323,1.102-5.253,4.018-6.971,6.91c-2.065,3.478-4.084,7.423-3.88,11.554c0.218,4.438,7.64,10.788-0.106,13.364
				C68.774,72.161,69.558,75.059,71.398,74.446L71.398,74.446z"/>
                    </g>
                </g>
            </g>
            <g id="Layer_2" display="none">
                <g display="inline">
                    <g>
                        <path d="M38.456,16.98c3.148-1.879,7.392-2.179,10.947-2.499c2.764-0.249,9.178-1.212,11.378,0.978
				c1.372,1.366,3.493-0.755,2.121-2.121c-2.617-2.605-7.937-2.103-11.257-1.995c-4.732,0.154-10.542,0.564-14.704,3.047
				C35.282,15.379,36.79,17.974,38.456,16.98L38.456,16.98z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M63.366,16.92c2.051-1.663,4.791-3.615,7.193-1.14c1.105,1.139,1.984,2.617,2.872,3.931
				c1.409,2.086,3.071,4.051,4.342,6.213c3.357,5.711,0.441,13.661-1.948,19.209c-0.671,1.557-3.641,7.071-4.657,2.59
				c-0.494-2.182-0.382-4.574-0.408-6.8c-0.082-7.024-2.286-14.583-0.449-21.504c0.497-1.873-2.396-2.666-2.893-0.798
				c-1.482,5.586-0.491,11.704,0.01,17.369c0.369,4.172,0.198,8.389,0.848,12.531c0.337,2.144,1.492,4.521,3.968,4.521
				c3.202,0,5.052-4.008,6.172-6.395c3.599-7.667,5.859-17.361,0.336-24.641c-2.445-3.225-4.395-7.186-7.596-9.746
				c-3.506-2.804-7.191,0.333-9.91,2.538C59.742,16.018,61.878,18.127,63.366,16.92L63.366,16.92z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M37.812,15.972c-8.79-5.177-10.459,4.518-16.438,8.893c-1.956,1.431-4.403,3.291-4.488,5.977
				c-0.152,4.762,1.498,9.612,2.979,14.075c1.495,4.503,5.402,15.251,10.378,7.946c1.947-2.859,1.281-7.618,1.135-10.814
				c-0.229-4.993,0.148-9.621-1.612-14.391c-0.664-1.799-3.564-1.021-2.893,0.798c1.909,5.175,1.392,10.674,1.613,16.103
				c0.033,0.816-0.484,8.727-1.988,7.409c-2.363-2.072-3.245-6.356-4.208-9.255c-0.784-2.362-1.402-4.77-1.93-7.2
				c-0.929-4.28,0.095-6.277,3.441-8.726c4.051-2.964,6.541-11.731,12.496-8.224C37.968,19.547,39.477,16.953,37.812,15.972
				L37.812,15.972z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M29.282,46.385c2.417,5.449,8.141,9.597,13.786,5.821c1.599-1.069,0.099-3.669-1.515-2.59
				c-4.3,2.876-7.947-0.835-9.68-4.746C31.089,43.105,28.505,44.633,29.282,46.385L29.282,46.385z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M40.141,53.012c4.884,2.882,10.705,2.748,14.822-1.382c1.367-1.371-0.754-3.492-2.121-2.121
				c-3.181,3.189-7.457,3.113-11.187,0.913C39.985,49.437,38.477,52.03,40.141,53.012L40.141,53.012z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M43.606,52.699c-0.881,5.931-3.002,10.566-5.851,15.73c-0.878,1.593-1.439,3.286-1.741,5.076
				c-0.249,1.475-0.109,2.978-0.324,4.457c-0.202,1.383-1.524,2.809-2.102,4.063c-1.605,3.485,0.175,7.118,4.23,6.83
				c4.954-0.352,7.36-7.405,8.495-11.242c0.551-1.859-2.345-2.647-2.893-0.797c-0.693,2.341-1.559,4.482-2.852,6.549
				c-0.922,1.871-2.459,2.343-4.61,1.416c-0.175-1.355,1.147-3.021,1.826-4.125c1.493-2.429,0.83-5.425,1.476-8.089
				c0.624-2.57,2.427-4.869,3.666-7.168c1.993-3.7,2.958-7.776,3.571-11.904C46.78,51.604,43.889,50.791,43.606,52.699
				L43.606,52.699z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M44.691,72.841c3.56,0.276,1.894,6.948,1.651,9.211c-0.318,2.955-0.673,6.511,1.58,8.822
				c1.84,1.888,5.699,1.736,6.703-0.919c0.644-1.704,0.503-3.521,0.431-5.31c-0.158-3.893-0.193-7.783-0.194-11.679
				c0-1.935-3-1.935-3,0c0.001,4.873,0.381,9.778,0.242,14.645c-0.085,2.979-2.725,0.692-2.929-0.996
				c-0.183-1.501,0.018-3.068,0.167-4.563c0.456-4.563,1.771-11.713-4.651-12.211C42.761,69.691,42.775,72.692,44.691,72.841
				L44.691,72.841z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M53.446,52.385c-0.133,3.208,1.298,7.42,4.23,9.145c2.761,1.623,5.487,2.635,7.795,5.021
				c3.083,3.188,5.652,7.112,6.05,11.622c0.283,3.218-2,5.526-4.02,7.632c-1.433,1.494-2.043,2.431-4.328,2.699
				c-5.274,0.62,1.847-5.119,1.126-4.518c3.596-3.004,4.402-6.001,0.577-9.385c-1.443-1.276-3.573,0.837-2.121,2.121
				c4.807,4.252-8.924,8.165-3.219,13.732c3.85,3.756,8.74-1.313,11.247-3.692c4.783-4.537,4.234-10.607,1.506-16.05
				c-1.825-3.64-4.966-6.93-8.141-9.398c-3.602-2.8-7.944-3.112-7.703-8.929C56.526,50.45,53.526,50.458,53.446,52.385
				L53.446,52.385z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M53.504,80.013c3.278,0.912,6.309,1.552,9.648,0.542c1.847-0.559,1.06-3.454-0.797-2.893
				c-2.823,0.854-5.295,0.226-8.055-0.542C52.434,76.601,51.643,79.495,53.504,80.013L53.504,80.013z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M71.581,71.499c1.013-1.128,2.866-1.584,4.219-2.16c1.46-0.623,3.053-1.282,4.091-2.537
				c-0.854-0.354-1.707-0.707-2.561-1.061c0.103,1.603-1.109,3.127-1.975,4.377c-1.043,1.506-2.252,2.896-3.239,4.44
				c-1.046,1.635,1.552,3.138,2.59,1.514c1.952-3.052,5.877-6.382,5.624-10.331c-0.075-1.177-1.657-2.153-2.561-1.061
				c-2.064,2.496-6.136,2.276-8.31,4.697C68.172,70.813,70.286,72.941,71.581,71.499L71.581,71.499z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M56.198,52.908c2.744-1.27,5.814-1.391,8.354-3.171c3.938-2.763,5.142-8.066,5.895-12.504
				c0.321-1.892-2.569-2.702-2.893-0.798c-0.698,4.119-1.632,9.161-5.596,11.393c-2.222,1.25-4.953,1.417-7.273,2.491
				C52.93,51.13,54.455,53.715,56.198,52.908L56.198,52.908z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M54,32.167c1.935,0,1.935-3,0-3S52.065,32.167,54,32.167L54,32.167z" />
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M36.667,36.5c1.935,0,1.935-3,0-3S34.732,36.5,36.667,36.5L36.667,36.5z" />
                    </g>
                </g>
                <ellipse display="inline" stroke="#000000" strokeMiterlimit="10" cx="46.556" cy="45.598" rx="3.973" ry="2.758" />
            </g>
            <g id="Layer_3" display="none">
                <g display="inline">
                    <g>
                        <path d="M38.02,53.054c-3.871,1.302-6.578-1.327-7.996-4.722c-1.915-4.583,0.995-7.042,4.841-8.779
				c3.954-1.786,5.202-1.926,3.201-6.185c-3.983-8.479-17.815-9.016-15.394-20.388c-0.615,0.349-1.23,0.698-1.845,1.047
				c2.203,0.736,3.502,1.991,5.357,3.314c1.852,1.32,3.723,1.883,5.713,2.939c3.215,1.707,6.036,3.329,7.755,6.689
				c2.024,3.956,2.718,9.933,8.331,8.248c1.84-0.553,2.642-1.188,4.533-0.32c1.3,0.596,2.365,0.868,3.795,0.776
				c2.315-0.148,3.945-2.299,5.196-3.992c5.166-6.989,12.761-16.642,22.441-16.236c-0.432-0.752-0.863-1.505-1.295-2.257
				c-1.383,2.345-3.383,4.272-5.101,6.371c-1.669,2.04-2.174,4.167-3.28,6.493c-1.671,3.508-4.361,4.258-7.429,6.041
				c-1.928,1.121-3.306,3.102-4.682,4.79c-0.563,0.691-0.55,1.429,0,2.121c2.789,3.515,5.47,8.545,2.019,12.625
				c-2.564,3.031-8.273,3.422-11.654,1.928c-1.752-0.774-3.28,1.81-1.514,2.59c5.114,2.261,11.092,1.287,15.289-2.396
				c5.317-4.667,1.436-12.515-2.019-16.867c0,0.707,0,1.414,0,2.121c1.737-2.131,3.239-3.958,5.727-5.21
				c2.218-1.117,3.998-1.781,5.438-3.928c1.598-2.385,2.245-5.115,3.732-7.501c1.727-2.771,4.394-4.829,6.063-7.662
				c0.598-1.013-0.177-2.21-1.295-2.257c-7.255-0.304-13.002,4.5-17.992,9.216c-2.496,2.36-4.573,4.66-6.405,7.552
				c-1.532,2.42-3.555,3.995-6.599,2.598c-1.034-0.474-1.801-0.59-2.866-0.401c-1.305,0.232-2.607,0.825-3.876,1.206
				c-1.319,0.396-2.707-4.211-2.949-4.815c-1.063-2.646-2.314-5.059-4.462-6.98c-1.615-1.444-4.555-2.951-6.583-3.728
				c-3.89-1.489-6.656-4.645-10.591-5.959c-0.743-0.248-1.682,0.278-1.845,1.047c-1.165,5.472,0.122,9.76,4.558,13.233
				c2.073,1.623,4.4,2.901,6.56,4.402c1.638,1.139,3.018,2.528,4.109,4.196c0.39,0.684,0.74,1.387,1.052,2.11
				c-0.114-0.244,1.331-0.929-0.388-0.188c-5.854,2.523-10.306,5.031-8.864,12.145c1.114,5.494,6.512,9.714,12.011,7.866
				C40.641,55.333,39.859,52.436,38.02,53.054L38.02,53.054z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M34.276,66.334c-0.008,3.096-1.578,18.937,3.617,18.997c7.157,0.083,4.523-18.721,4.492-22.676
				c-0.016-1.934-3.016-1.936-3,0c0.027,3.352,0.201,6.699,0.188,10.052c-0.008,2.038-0.089,4.089-0.369,6.108
				c-0.146,1.057-1.296,3.211-0.719,3.678c-1.533-1.24-1.096-6.52-1.161-8.269c-0.097-2.629-0.054-5.261-0.047-7.891
				C37.282,64.399,34.282,64.399,34.276,66.334L34.276,66.334z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M48.141,64.543c-0.512,3.694-0.956,7.398-1.19,11.122c-0.154,2.443-0.681,6.343,0.984,8.436
				c1.782,2.239,4.072-0.387,5.161-1.854c2.821-3.802,2.099-9.723,2.083-14.119c-0.007-1.935-3.007-1.935-3,0
				c0.012,3.274,0.218,6.608-0.478,9.83c-0.168,0.711-0.42,1.392-0.757,2.042c-0.146,0.244-0.292,0.488-0.439,0.732
				c-1.146,0.684-1.257,1.113-0.333,1.289c-0.312-0.855-0.282-1.854-0.311-2.751c-0.146-4.633,0.538-9.352,1.173-13.931
				C51.296,63.449,48.406,62.634,48.141,64.543L48.141,64.543z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M52.179,55.892c0.287,4.57,3.034,7.564,6.096,10.719c2.843,2.93,4.425,5.359,4.384,9.589
				c-0.028,2.946-1.928,6.471-5.205,6.426c-1.936-0.026-1.933,2.974,0,3c5.262,0.072,7.71-4.865,8.205-9.426
				c1.007-9.271-9.96-12.038-10.479-20.308C55.059,53.971,52.058,53.959,52.179,55.892L52.179,55.892z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M37.667,55.892c-0.391,7.058-12.181,7.013-13.51,14.65c-1.103,6.333,2.465,13.529,9.341,13.898
				c1.934,0.104,1.924-2.896,0-3c-4.048-0.217-5.912-3.761-6.391-7.4c-0.584-4.441,1.55-6.607,5.093-8.625
				c4.104-2.338,8.176-4.275,8.467-9.523C40.774,53.958,37.773,53.968,37.667,55.892L37.667,55.892z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M30.261,81.494c-2.302,0.85-12.454,6.702-7.971,10.138c2.168,1.661,6.242,0.417,8.377-0.607
				c2.986-1.431,5.529-2.66,8.938-2.653c3.243,0.006,6.475,0.159,9.711-0.113c4.36-0.368,9.288,2.793,13.646,3.608
				c2.86,0.535,6.953,1.359,9.455-0.57c5.308-4.094-7.318-8.548-9.254-9.65c-1.684-0.959-3.194,1.633-1.514,2.59
				c1.496,0.853,3.137,1.459,4.701,2.176c0.978,0.447,1.905,0.968,2.825,1.522c3.091,1.861-1.817,1.458-2.576,1.416
				c-2.447-0.138-4.863-0.776-7.239-1.335c-2.922-0.687-5.61-2.664-8.692-2.729c-3.244-0.068-6.512,0.133-9.767,0.106
				c-2.283-0.02-4.725-0.24-6.875,0.683c-1.235,0.53-2.414,1.131-3.606,1.753c0.128-0.066-6.261,2.191-5.802,1.319
				c1.175-2.233,4.175-3.924,6.441-4.761C32.857,83.723,32.08,80.822,30.261,81.494L30.261,81.494z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M43.589,42.17c1.935,0,1.935-3,0-3S41.655,42.17,43.589,42.17L43.589,42.17z" />
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M52.588,42.443c1.935,0,1.935-3,0-3S50.653,42.443,52.588,42.443L52.588,42.443z" />
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M40.736,51.744c2.956,0.973,5.582-0.445,6.583-3.337c-0.982-0.133-1.964-0.266-2.946-0.399
				c0.066,2.4,1.181,4.991,3.982,4.808c1.92-0.126,1.933-3.126,0-3c-0.97,0.063-0.96-1.039-0.982-1.808
				c-0.048-1.738-2.397-1.984-2.946-0.399c-0.487,1.406-1.57,1.677-2.893,1.242C39.691,48.244,38.908,51.142,40.736,51.744
				L40.736,51.744z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M67.331,29.899c0.628-2.241,3.621-3.754,5.377-5.025c1.551-1.123,0.057-3.728-1.514-2.591
				c-2.477,1.792-5.874,3.667-6.756,6.818C63.916,30.968,66.811,31.759,67.331,29.899L67.331,29.899z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M26.01,22.058c5.678,1.079,10.613,4.94,12.344,10.558c0.568,1.843,3.464,1.056,2.893-0.798
				c-2.023-6.565-7.735-11.378-14.439-12.652C24.917,18.806,24.111,21.697,26.01,22.058L26.01,22.058z"/>
                    </g>
                </g>
            </g>
            <g id="Layer_4" display="none">
                <g display="inline">
                    <g>
                        <path d="M36.759,42c-0.568,0.057-1.527-4.068-1.681-4.787c-0.148-0.692-0.258-1.302-0.067-1.987
				c0.268-0.96,1.621-3.339,2.923-2.765c1.104,0.486,0.595,4.046,0.505,4.913c-0.127,1.225-0.476,3.916-1.633,4.663
				c-1.618,1.046-0.116,3.644,1.515,2.591c3.744-2.42,5.391-17.199-1.555-15.193c-3.467,1.001-5.212,4.755-4.67,8.104
				c0.355,2.19,1.505,7.774,4.663,7.46C38.666,44.81,38.684,41.809,36.759,42L36.759,42z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M62.914,59.728c2.836,3.461,7.957-0.191,10.354-2.453c4.675-4.409,2.076-12.38-1.076-16.698
				c-0.179,0.685-0.358,1.369-0.538,2.053c2.702-1.897,4.761-2.545,6.178-5.718c1.226-2.746,1.271-6.205,1.074-9.139
				c-0.406-6.049-4.998-10.703-9.749-13.874c-6.374-4.254-14.313-5.407-21.795-5.793c-6.213-0.321-15.235-1.121-20.443,3.173
				c-5.381,4.438-5.036,12.42-4.59,18.789c0.242,3.446,1.119,6.593,2.273,9.822c0.586,1.638-2.468,6.695-2.736,8.761
				c-0.63,4.856,0.877,10.431,4.438,13.859c2.627,2.529,8.358,2.965,11.032,0.218c1.35-1.386-0.77-3.51-2.121-2.121
				c-4.226,4.34-8.885-2.323-9.88-5.678c-0.604-2.036-0.611-4.182-0.469-6.278c0.165-2.433,2.021-3.646,2.733-5.715
				c1.099-3.189-1.334-6.927-1.926-10.09c-1.017-5.433-1.625-14.333,2.391-18.64c3.652-3.917,10.935-3.128,15.745-3.168
				c6.178-0.052,12.381,0.609,18.229,2.665c5.076,1.785,9.72,5.12,12.47,9.777c1.525,2.583,1.558,5.553,1.383,8.441
				c-0.268,4.421-2.469,5.814-5.75,8.118c-0.615,0.432-1.049,1.353-0.538,2.053c2.272,3.113,3.556,6.355,2.858,10.278
				c-0.278,1.563-5.204,7.947-7.426,5.237C63.809,56.109,61.698,58.244,62.914,59.728L62.914,59.728z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M68.216,15.143c1.786-1.395,3.895-2.47,6.03-3.209c-0.614-0.349-1.229-0.698-1.845-1.047
				c0.454,2.604,0.955,5.358,0.579,8.006c-0.269,1.892,2.622,2.707,2.893,0.798c0.458-3.227-0.024-6.416-0.579-9.602
				c-0.134-0.77-1.126-1.296-1.845-1.047c-2.662,0.92-5.136,2.248-7.354,3.98C64.571,14.211,66.71,16.319,68.216,15.143
				L68.216,15.143z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M27.544,11.184c-2.348,0.073-4.669-0.755-6.775-1.729c-0.956-0.442-1.81-0.052-2.203,0.896
				c-1.544,3.724,2.054,7.406,2.898,10.881c0.457,1.879,3.349,1.082,2.893-0.798c-0.44-1.812-1.205-3.466-2.063-5.114
				c-0.713-1.372-1.472-2.638-0.836-4.171c-0.734,0.299-1.469,0.598-2.203,0.896c2.59,1.197,5.396,2.228,8.289,2.138
				C29.474,14.124,29.48,11.124,27.544,11.184L27.544,11.184z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M37.877,64.723c-1.483,3.254-2.04,6.274-0.468,9.625c1.094,2.33,1.967,3.848,1.276,6.508
				c-0.518,1.996-1.034,3.48-0.671,5.534c0.184,1.045,1.835,1.527,2.507,0.662c0.525-0.676,1.046-1.489,1.811-1.932
				c-0.734-0.299-1.469-0.598-2.203-0.896c0.415,1.352,1.02,2.175,1.915,3.259c0.779,0.945,2.16,0.294,2.507-0.662
				c0.375-1.033,0.95-6.866,2.469-6.24c1.556,0.642,2.954,1.09,4.516,0.209c2.416-1.363,3.276-1.861,3.838,1.342
				c0.404,2.311,0.398,4.683,1.102,6.935c0.418,1.34,1.973,1.368,2.741,0.358c-0.799-0.209-0.938-0.155-0.417,0.162
				c0.414,0.179,0.865,0.453,1.333,0.453c1.155,0,2.098-0.582,2.677-1.57c0.656-1.121,0.325-2.249,0.019-3.386
				c-0.544-2.019-0.744-3.765-0.655-5.875c0.211-5.02,2.791-14.62-4.118-16.321c-1.878-0.463-2.675,2.431-0.797,2.893
				c4.624,1.139,1.895,12.033,1.861,15.479c-0.012,1.239,0.73,5.35,0.701,5.332c-1.461-0.855-2.397,0.274-3.193,1.319
				c0.914,0.119,1.827,0.238,2.741,0.358c-0.813-2.604-0.37-14.449-6.067-11.9c-2.156,0.964-3.434,2.221-5.922,1.117
				c-0.723-0.321-1.626-0.653-2.432-0.432c-3.205,0.881-2.487,6.759-3.289,8.97c0.835-0.221,1.671-0.441,2.507-0.662
				c-0.381-0.646-0.762-1.29-1.143-1.935c-0.284-0.927-1.346-1.393-2.203-0.896c-1.278,0.737-1.431,1.128-2.418,2.4
				c0.835,0.221,1.671,0.441,2.507,0.662c-0.549-3.109,1.67-5.756,0.801-8.988c-1.083-4.029-3.321-5.805-1.24-10.368
				C41.264,64.49,38.679,62.964,37.877,64.723L37.877,64.723z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M52.943,58.333c-3.572-0.205-8.666,2.555-11.634-0.7c-2.165-2.373,2.401-10.481,4.683-10.677
				c4.598-0.394,13.42,8.841,7.08,11.582c-1.771,0.766-0.242,3.35,1.514,2.59c7.647-3.306,1.599-12.47-2.899-15.59
				c-2.451-1.7-7.006-2.538-9.225-0.099c-2.175,2.391-4.107,6.305-4.524,9.543c-0.564,4.376,2.666,6.557,6.592,7.032
				c1.806,0.219,3.661,0.056,5.457-0.184c0.745-0.1,1.486-0.215,2.226-0.349c0.298-0.055,1.368-0.113,0.731-0.149
				C54.877,61.443,54.866,58.443,52.943,58.333L52.943,58.333z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M62.961,71.846c2.265-0.34,5.237,0.306,6.466-2.178c1.581-3.195-2.678-4.886-5.052-3.604
				c-5.484,2.962,1.688,8.336,4.97,9.074c1.884,0.425,2.685-2.468,0.797-2.893c-0.996-0.225-1.897-0.739-2.754-1.279
				c-0.573-0.361-1.88-1.169-1.94-1.934c-0.016-0.197,0.558-0.448,0.709-0.499c0.175-0.059,0.479-0.019,0.625,0.102
				c-0.276-0.227,0.182-0.254-0.411-0.076c-1.334,0.398-2.834,0.188-4.206,0.394C60.256,69.239,61.069,72.13,62.961,71.846
				L62.961,71.846z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M59.941,40.439c-1.357-1.054-1.666-2.917-1.714-4.541c-0.022-0.765,0.025-3.382,1.191-3.371
				c2.935,0.027,4.105,8.49,0.664,7.859c-1.891-0.347-2.698,2.544-0.797,2.893c7.753,1.421,7.097-10.816,1.692-13.346
				c-6.678-3.126-6.932,9.697-3.158,12.627C59.328,43.732,61.468,41.625,59.941,40.439L59.941,40.439z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M47.438,56.833c-0.154-1.552-0.161-3.108-0.161-4.666c0-1.935-3-1.935-3,0c0,1.558,0.007,3.114,0.161,4.666
				c0.081,0.813,0.621,1.5,1.5,1.5C46.695,58.333,47.52,57.648,47.438,56.833L47.438,56.833z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M51.464,56.167c0-1.778,0-3.556,0-5.333c0-1.935-3-1.935-3,0c0,1.778,0,3.555,0,5.333
				C48.464,58.102,51.464,58.102,51.464,56.167L51.464,56.167z"/>
                    </g>
                </g>
                <g display="inline">
                    <g>
                        <path d="M35.787,64.1c1.95,0.754,4.198,2.337,6.347,2.603c3.155,0.391,6.374,0.3,9.549,0.298
				c4.966-0.004,9.495-2.108,12.71-5.834c1.257-1.457-0.855-3.589-2.121-2.121c-3.234,3.748-7.21,4.952-12.055,4.956
				c-2.182,0.002-4.375-0.021-6.554-0.109c-2.209-0.091-4.92-1.85-7.078-2.685C34.778,60.508,34.003,63.41,35.787,64.1L35.787,64.1z
				"/>
                    </g>
                </g>
                <circle display="inline" stroke="#000000" strokeMiterlimit="10" cx="37.345" cy="39.846" r="2.095" />
                <circle display="inline" stroke="#000000" strokeMiterlimit="10" cx="60.281" cy="39.656" r="2.281" />
            </g>
        </svg>

    )
}
