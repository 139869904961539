import {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useBooking} from "../context/BookingContext";

type FlightType = {
  name: string,
  departure: string,
  arrival: string,
  roundTrip: string,
  airline: string,
  seatClass: string,
  flightNo: string,
}

export default function Flight() {
  const {state: {airlines, airports, seatClasses, names, }} = useBooking();
  const {register, handleSubmit, setValue} = useForm<FlightType>({
    defaultValues: {
      name: "",
      departure: "",
      arrival: "",
      roundTrip: "",
      airline: "",
      seatClass: "",
      flightNo: "",
    }
  });
  const [copy, setCopy] = useState(false);
  const [textToCopy, setTextToCopy] = useState<string>('');
  const copyButtonRef = useRef<HTMLButtonElement>(null);

  const onSubmit = handleSubmit(async (data, event) => {
    event?.preventDefault();
    const flightHolder = data.roundTrip ? `${data.departure} - ${data.arrival} - ${data.departure}` : `${data.departure} - ${data.arrival}`
    setTextToCopy(`Travel for ${capitalizeWords(data.name)}, ${flightHolder}, ${data.airline}, ${data.seatClass}, ${data.flightNo}`)
  });

  useEffect(() => {
    async function wait() {
      await new Promise(() => {
        setTimeout(() => {
          setCopy(false);
        }, 500)
      })
    }
    if (copy) {
      wait();
    }
  }, [copy])

  function handleCopy() {
    navigator.clipboard.writeText(textToCopy)
    setCopy(true);
  }

  function capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  }


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }


  return (
    <div className="flex flex-col gap-2 place-content-center w-full h-fit dark:text-neutral-50 ">
      <form onSubmit={(e) => onSubmit(e)} className={`flex flex-col md:gap-2  border border-black bg-neutral-200 dark:bg-neutral-800 border-opacity-50 dark:border-opacity-30 dark:border-neutral-50 shadow-md rounded p-2 w-full`}>
        <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
          <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Name</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2 grow dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("name")} />
          <select disabled={names.length === 0} className="dark:bg-neutral-700  px-2 cursor-pointer w text-sm " onChange={(e) => {setValue("name", e.target.value)}}>
            <option value="">Select</option>
            {names.map((name, i) => {
              return (<option key={`${name}_${i}`} value={name}>{name}</option>)
            })}
          </select>
        </label>
        <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2  mb-1">
          <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Departure</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2 grow  dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("departure")} />
          <select disabled={airports.length === 0} className="dark:bg-neutral-700 bg-neutral-100 px-2 cursor-pointer w-24 text-sm" onChange={(e) => {setValue("departure", e.target.value)}}>
            <option value="">Select</option>
            {airports.map((airport, i) => {
              return (<option key={`${airport}_${i}`} value={airport}>{airport}</option>)
            })}
          </select>
        </label>
        <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
          <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Arrival</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`flex justify-stretch border rounded pl-2 dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("arrival")} />
          <select disabled={airports.length === 0} className="dark:bg-neutral-700 bg-neutral-100 px-2 cursor-pointer w-24 text-sm" onChange={(e) => {setValue("arrival", e.target.value)}}>
            <option value="">Select</option>
            {airports.map((airport, i) => {
              return (<option key={`${airport}_${i}`} value={airport}>{airport}</option>)
            })}
          </select>
        </label>
        <div className="flex h-8 items-center">
          <label htmlFor="roundTrip" className='w-24 flex-none'>Round Trip</label>
          <input className={`border rounded pl-2 p-1`} type="checkbox" {...register("roundTrip")} />
        </div>
        <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
          <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Airline</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2  grow  dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("airline")} />
          <select disabled={airlines.length === 0} className="dark:bg-neutral-700 bg-neutral-100 px-2 cursor-pointer w-24 text-sm" onChange={(e) => {setValue("airline", e.target.value)}}>
            <option value="">Select</option>
            {airlines.map((airline, i) => {
              return (<option key={`${airline}_${i}`} value={airline}>{airline}</option>)
            })}
          </select>
        </label>
        <label className="grid grid-cols-[1fr,_96px] sm:grid-cols-[80px,_1fr,_96px] gap-x-2 md:gap-2 mb-1">
          <span className='col-span-2 sm:col-span-1 sm:w-20 flex-none'>Seat Class</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded pl-2  grow dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("seatClass")} />

          <select disabled={seatClasses.length === 0} className="dark:bg-neutral-700 bg-neutral-100 px-2 cursor-pointer w-24 text-sm" onChange={(e) => {setValue("seatClass", e.target.value)}}>
            <option value="">Select</option>
            {seatClasses.map((seatClass, i) => {
              return (<option key={`${seatClass}_${i}`} value={seatClass}>{seatClass}</option>)
            })}
          </select>
        </label>
        <label className="flex mb-2">
          <span className='w-24 flex-none'>Flight no.</span>
          <input onKeyDown={(e) => handleKeyDown(e)} className={`border rounded -ml-2 w-full dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="text" {...register("flightNo")} />
        </label>
        <button className={`border border-black rounded p-1 px-12 hover:bg-neutral-300 dark:hover:bg-neutral-500 bg-neutral-100 dark:bg-neutral-700 dark:border-opacity-30 dark:border-neutral-50`} type="submit">Write</button>
      </form>
      <div className={`flex border border-black bg-neutral-200 dark:bg-neutral-800 border-opacity-50 p-2 gap-2 w-full rounded shadow-xl dark:border-opacity-30 dark:border-neutral-50`}>
        <label className="flex w-full">
          <span className='w-24 flex-none'>Output</span>
          <textarea className={`border border-black rounded h-32 p-1 pl-2 w-full border-opacity-30 dark:border-opacity-30 dark:border-neutral-50 dark:bg-neutral-700`} value={textToCopy} onChange={(event) => setTextToCopy(event.target.value)} />
        </label>
        <button className={`bg-neutral-100 dark:bg-neutral-300 border-opacity-40 h-fit border border-black rounded-full p-2 my-auto`} ref={copyButtonRef} onClick={handleCopy}>
          {!copy ? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#222"><path d="M0 0h24v24H0z" fill="none" /><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" /></svg> :
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
          }
        </button>
      </div>
    </div>
  );
}
