import {Link, Route, Routes} from "react-router-dom"
import {ContactOutlineSVG} from "../svg/ContactOutlineSVG"
import {ContactSelectedSVG} from "../svg/ContactSelectedSVG copy 2"
import {FeaturedOutlineSVG} from "../svg/FeaturedOutlineSVG"
import {FeaturedSelectedSVG} from "../svg/FeaturedSelectedSVG"
import {WorkOutlineSVG} from "../svg/WorkOutlineSVG"
import {WorkSelectedSVG} from "../svg/WorkSelectedSVG"

type Props = {
    setSelected: (selection: string) => void
    selected: string
}

export const BottomNav = ({setSelected, selected}: Props) => {
    return (
        <Routes>
            <Route path="/webapps/*" element={
                <></>
            } />
            <Route path="*" element={
                <div className="md:hidden flex w-full border-t dark:bg-neutral-900 dark:border-neutral-50 dark:border-opacity-25">
                    <ul className="flex w-full h-20 justify-evenly dark:text-neutral-50 dark:fill-neutral-50" style={{WebkitTapHighlightColor: "transparent"}}>
                        <Link to="featured">
                            <li onClick={() => setSelected("featured")} className={(selected === "featured" || !selected) ? "p-5 border-t-2 border-black dark:border-neutral-400" : "border-t-2 border-transparent p-5"}>
                                <div className="flex flex-col items-center w-12">
                                    {(selected === "featured" || !selected) ? <FeaturedSelectedSVG className="w-6 h-6 dark:fill-yellow-400" innerFill="#facc15" /> : <FeaturedOutlineSVG className="w-6 h-6" />}
                                    <p>Featured</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="projects">
                            <li onClick={() => setSelected("projects")} className={selected === "projects" ? "p-5 border-t-2 border-black dark:border-neutral-400" : "border-t-2 border-transparent p-5"}>
                                <div className="flex flex-col items-center w-12">
                                    {selected === "projects" ? <WorkSelectedSVG className="w-6 h-6 dark:fill-blue-400" innerFill="#60a5fa" /> : <WorkOutlineSVG className="w-6 h-6" />}
                                    <p>Projects</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="contact">
                            <li onClick={() => setSelected("contact")} className={selected === "contact" ? "p-5 border-t-2 border-black dark:border-neutral-400" : "border-t-2 border-transparent p-5"}>
                                <div className="flex flex-col items-center w-12">
                                    {selected === "contact" ? <ContactSelectedSVG className="w-6 h-6 dark:fill-red-400" innerFill="#f87171" /> : <ContactOutlineSVG className="w-6 h-6" />}
                                    <p>Contact</p>
                                </div>
                            </li>
                        </Link>
                    </ul>
                </div>
            } />
        </Routes>

    )
}