type Props = {
    className?: string;
}

export const BirdUpSVG = ({ className }: Props) => {
    return (
        <svg className={className} viewBox="1 -1 16 16" xmlns="http://www.w3.org/2000/svg" shapeRendering={"crispEdges"}>
            <rect x="6" y="6" fill="#231F20" width="1" height="2" />
            <g>
                <rect x="13" y="7" fill="none" width="1" height="1" />
                <rect x="1" y="7" fill="#231F20" width="1" height="2" />
                <rect x="2" y="9" fill="#231F20" width="3" height="1" />
                <polygon fill="#231F20" points="8,11 8,10 5,10 5,11 6,11 6,13 7,13 7,11" />
                <rect x="7" y="13" fill="#231F20" width="2" height="1" />
                <rect x="10" y="13" fill="#231F20" width="2" height="1" />
                <polygon fill="#231F20" points="13,7 12,7 12,13 13,13 13,9 14,9 14,8 13,8" />
                <rect x="14" y="7" fill="#231F20" width="1" height="1" />
                <rect x="13" y="6" fill="#231F20" width="1" height="1" />
                <rect x="14" y="3" fill="#231F20" width="1" height="3" />
                <polygon fill="#231F20" points="11,2 11,1 8,1 8,2 5,2 5,3 8,3 8,2 11,2 11,3 14,3 14,2" />
                <rect x="4" y="3" fill="#231F20" width="1" height="1" />
                <rect x="3" y="4" fill="#231F20" width="1" height="1" />
                <rect x="2" y="5" fill="#231F20" width="1" height="2" />
                <polygon fill="#231F20" points="9,7 9,9 8,9 8,10 9,10 9,13 10,13 10,7" />
            </g>
            <polygon fill="#FFCB2A" points="3,8 3,7 2,7 2,9 3,9 4,9 4,8 " />
            <rect x="13" y="7" fill="#FFCB2A" width="1" height="1" />
            <g>
                <path fill="#FFFFFF" d="M11,3V2H8v1H5v1H4v1H3v1v2h1v1h1v1h3V9h1V8V7h1v6h2V7h1V6h1V5V4V3H11z M7,7v1H6V6h1V7z" />
                <polygon fill="#FFFFFF" points="7,11 7,13 8,13 9,13 9,10 8,10 8,11" />
            </g>
        </svg>
    )
}
