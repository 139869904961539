import React, {useState, useEffect, useCallback, ReactNode} from 'react';
import {useUser} from '../../contexts/UserContext'
import {useAuth} from '../../contexts/AuthContext';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {ChangePassword} from '../../common/modals/ChangePassword';
import {EmailSVG} from '../../common/svg/EmailSVG';
import {PasswordSVG} from '../../common/svg/PasswordSVG';
import {DeleteSVG} from '../../common/svg/DeleteSVG';
import {ChangeEmail} from '../../common/modals/ChangeEmail';
import {DeleteAccount} from '../../common/modals/DeleteAccount';
import {Input} from '../../common/styled/Input';
import {SubmitButton} from '../../common/styled/SubmitButton';
import {Button} from '../../common/styled/Button';
import {Avatar} from '../../common/Avatar';
import {AVATARS, BG_COLORS} from '../../shared/consts';
import {Footer} from '../../common/layouts/Footer';
import {Form} from '../../common/styled/Form';
import {PageCard} from '../../common/styled/decorators/PageCard';

type Props = {
    openModal: (modal: ReactNode) => void
    closeModal: () => void
}

export const Account = ({openModal, closeModal}: Props) => {
    const navigate = useNavigate()
    const location: any = useLocation()

    const {currentUser, emailVerified} = useAuth()
    const {editProfile: handleEditProfile, avatar, avatarColor, displayName} = useUser()
    const [isLoading, setIsLoading] = useState(false)
    const [inputDisplayName, setInputDisplayName] = useState(displayName)
    const [avatarKeyName, setAvatarKeyName] = useState(avatar)
    const [inputAvatarColor, setAvatarColor] = useState(avatarColor)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsLoading(true)
        await handleEditProfile({
            displayName: inputDisplayName,
            avatar: avatarKeyName,
            avatarColor: inputAvatarColor
        })
        setIsLoading(false)
    }

    useEffect(() => {
        if (inputDisplayName.length === 0) {
            setInputDisplayName(displayName)
        }
    }, [inputDisplayName, setInputDisplayName, displayName])

    const handleAvatar = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, avatarKeyName: string) => {
        e.preventDefault();
        setAvatarKeyName(avatarKeyName)
    }, [])

    const handleAvatarColor = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, backgroundColor: string) => {
        e.preventDefault();
        setAvatarColor(backgroundColor)
    }, [])

    useEffect(() => {
        if (!currentUser || !emailVerified) return navigate("/")
    }, [currentUser, emailVerified, navigate])

    const handleDelete = useCallback(() => {
        openModal(<DeleteAccount closeModal={closeModal} />)
    }, [openModal, closeModal])

    const handleChangeEmail = useCallback(() => {
        openModal(<ChangeEmail closeModal={closeModal} />)
    }, [openModal, closeModal])

    const handleChangePassword = useCallback(() => {
        openModal(<ChangePassword closeModal={closeModal} />)
    }, [openModal, closeModal])

    useEffect(() => {
        if (location.state?.modal) {
            if (location.state?.modal === "changePassword") {
                openModal(<ChangePassword closeModal={closeModal} />)
            } else if (location.state?.modal === "deleteAccount") {
                openModal(<DeleteAccount closeModal={closeModal} />)
            } else if (location.state?.modal === "changeEmail") {
                openModal(<ChangeEmail closeModal={closeModal} />)
            }
        }
    }, [closeModal, location.state?.modal, openModal])

    if (!currentUser || !emailVerified) return <Navigate replace to="/" />
    if (currentUser === null || currentUser === undefined) return null
    return (
        <>
            <PageCard children={<>
                <Avatar className="w-24 h-24 md:w-36 md:h-36 p-4 rounded-full border border-black border-opacity-25" override={{avatar: avatarKeyName, bgColor: inputAvatarColor}} />
                <Form onSubmit={handleSubmit} children={<>
                    <label>Name</label>
                    <Input state={inputDisplayName} setState={setInputDisplayName} type="text" />
                    <label>Avatar</label>
                    <div className="w-full">
                        {
                            AVATARS.map((avatar, key) => {
                                return (<button key={key} onClick={(e) => handleAvatar(e, avatar)}>
                                    <Avatar className="rounded-full border w-10 h-10 m-1 border-opacity-25" override={{avatar: avatar, bgColor: "white"}} />
                                </button>
                                )
                            })
                        }
                    </div>
                    <label>Color</label>
                    <div className="w-full pb-6">
                        {
                            BG_COLORS.map((bgColor, key) => {
                                return <button key={key} onClick={(e) => handleAvatarColor(e, bgColor)} className="border rounded-full m-1 w-10 h-10 hover:animate-pulse" style={{backgroundColor: bgColor}} />
                            })
                        }
                    </div>
                    <SubmitButton disabled={isLoading} text={"Save Changes"} />
                </>} />
                {currentUser.providerData[0].providerId === "password" && <>
                    <Button onClick={handleChangeEmail} children={
                        <EmailSVG className="absolute top-0 left-0 mt-3.5 ml-3 h-6 w-6 fill-gray-600" />
                    } text="Change Email" />
                    <Button onClick={handleChangePassword} children={
                        <PasswordSVG className="absolute top-0 left-0 mt-3 ml-3 h-6 w-6 fill-gray-600" />
                    } text="Change Password" />
                </>}
                <Button onClick={handleDelete} children={
                    <DeleteSVG className="absolute top-0 left-0 mt-3 ml-3 h-6 w-6 fill-gray-600" />
                } text="Delete Account" />
            </>} />
            <Footer />
        </>
    )
}
