import {Routes, Route, useLocation} from 'react-router-dom';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';

import {NoMatch} from './pages/NoMatch';
import {Projects} from './pages/projects/Projects';
import {Home} from './pages/Home';
import {Contact} from './pages/Contact';
import {Account} from './pages/account/Account';
import {BottomNav} from './common/nav/BottomNav';
import {TopNav} from './common/nav/TopNav';
import {Background} from './common/layouts/Background';
import {Login} from './pages/account/Login';
import {Signup} from './pages/account/Signup';
import {PasswordRecovery} from './pages/account/PasswordRecovery';
import {EmailVerification} from './pages/account/EmailVerification';
import {Reauthentication} from './pages/account/Reauthentication';
import {RequireAuth} from './common/guard/RequireAuth';
import {SubstringSequencer} from './pages/projects/web/substringSequencer/SequencerApp';
import {StopwatchWrapper} from './pages/projects/web/stopwatch/StopwatchWrapper';
import {RequireNoAuth} from './common/guard/RequireNoAuth';
import {MobileCards} from './pages/projects/mobile/MobileCards';
import {WebCards} from './pages/projects/web/WebCards';
import {Modal} from './common/modals/Modal';
import {projectCategories} from './shared/consts';
import {BookingLayout} from './pages/projects/web/bookingAssistant/BookingLayout';
import {Privacy} from './pages/Privacy';

export const App = () => {
  const [selected, setSelected] = useState<string>(useLocation().pathname.substring(1))
  const [isShowing, setIsShowing] = useState(false);
  const [modal, setModal] = useState<ReactNode>();
  const divRef = useRef<HTMLDivElement | null>(null)

  const openModal = useCallback((modal: ReactNode, opacity?: string) => {
    setIsShowing(true)
    setModal(modal)
  }, [])

  const closeModal = useCallback(() => {
    setIsShowing(false)
  }, [])

  useEffect(() => {
    if (divRef === null) return;
    divRef.current?.scrollTo({
      top: 0,
      left: 0,
    });
  }, [selected]);

  return (
    <>
      <div className="fixed flex flex-col h-full w-full dark:bg-black ">
        <TopNav selected={selected} openModal={openModal} closeModal={closeModal} setSelected={setSelected} />
        <div ref={divRef} className="flex flex-col w-full h-full grow overflow-y-auto">
          <Background />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="featured" element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="projects" element={<Projects />}>
              <Route path={projectCategories.MOBILE} element={<MobileCards />} />
              <Route path={projectCategories.WEB} element={<WebCards />} />
            </Route>
            <Route element={<RequireNoAuth />}>
              <Route path="login" element={<Login />} />
              <Route path="recovery" element={<PasswordRecovery />} />
              <Route path="signup" element={<Signup />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="account" element={<Account openModal={openModal} closeModal={closeModal} />} />
              <Route path="reauthentication" element={<Reauthentication />} />
              <Route path="verification" element={<EmailVerification />} />
            </Route>
            <Route path="*" element={<NoMatch />} />
            <Route path="/webapps/substringsequencer" element={<SubstringSequencer />} />
            <Route element={<RequireAuth />}>
              <Route path="/webapps/stopwatch" element={<StopwatchWrapper />} />
              <Route path="/webapps/bookingassistant" element={<BookingLayout />} />
            </Route>
          </Routes>
        </div>
        <BottomNav selected={selected} setSelected={setSelected} />
      </div>
      <Modal
        isShowing={isShowing}
        modal={modal}
      />
    </>
  );
}