import { useEffect, useState } from "react";

type Props = {
    src: string,
    alt: string,
    className: string
  }
  
  export const LazyImage =  ({ src , alt, className } : Props)  => {
    const placeHolder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAwCAYAAABT9ym6AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABVSURBVGhD7c+xEcAgEMCwD/vvDCkYwslJjWs/+zU/sG4/z0iNkRojNUZqjNQYqTFSY6TGSI2RGiM1RmqM1BipMVJjpMZIjZEaIzVGaozUGKkx0jJzAF8tBFxgylxaAAAAAElFTkSuQmCC';
    const [imageSrc, setImageSrc] = useState(placeHolder)
    const [imageRef, setImageRef] = useState<any>()
    
    useEffect(() => {
      if(src !== imageSrc) setImageSrc(placeHolder)
    },[src,imageSrc])

    useEffect(() => {
      let observer: IntersectionObserver
      let didCancel = false
  
      if (imageRef && imageSrc === placeHolder) {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                // when image is visible in the viewport + rootMargin
                if (
                  !didCancel &&
                  (entry.intersectionRatio > 0 || entry.isIntersecting)
                ) {
                  setImageSrc(src)
                }
              })
            },
            {
              threshold: 0.01,
              rootMargin: '75%',
            }
          )
          observer.observe(imageRef)
        } else {
          // Old browsers fallback
          setImageSrc(src)
        }
      }
      return () => {
        didCancel = true
        // on component unmount, we remove the listner
        if (observer && observer.unobserve) {
          observer.unobserve(imageRef)
        }
      }
    },[imageRef,imageSrc,src])

    return <img className={className} style={{maxHeight:"34rem"}} ref={setImageRef} src={imageSrc} alt={alt}/>
  }