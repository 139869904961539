import { ReactNode } from "react"
import { Card } from "../Card"

type Props = {
    children: ReactNode
    className?: string
}

export const ModalCard = (({ children, className }: Props) => {
    return (
        <Card className={`${className} relative m-4`} children={children} />
    )
})