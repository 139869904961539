import { ReactChild } from "react"

type Props = {
    disabled?: boolean,
    text: string | ReactChild
}

export const SubmitButton = (({ disabled, text }: Props) => {
    return (
        <button className="w-full p-2 border 
        bg-blue-200 dark:bg-neutral-800
        border-black dark:border-neutral-50 
        border-opacity-25 dark:border-opacity-25
        hover:bg-blue-400 dark:hover:bg-neutral-700
        hover:text-white
        rounded-3xl disabled:opacity-75"
            disabled={disabled}
            type="submit"
        >
            {text}
        </button>
    )
})