import React, {useCallback, useContext, useEffect, useState} from 'react'
import {DARK, LIGHT} from '../shared/consts';


type AppValue = {
    isDark: boolean;
    changeTheme: () => null;
}

const AppContext = React.createContext<AppValue>({
    isDark: false,
    changeTheme: () => null,
})

export const useApp = () => useContext(AppContext)

export const AppProvider = ({children}: {children: React.ReactNode}) => {

    const [isDark, setIsDark] = useState(localStorage.theme !== LIGHT)

    useEffect(() => {
        if (isDark && localStorage.theme !== DARK) {
            document.documentElement.classList.add(DARK)
            localStorage.theme = DARK
        } else if (!isDark && localStorage.theme !== LIGHT) {
            document.documentElement.classList.remove(DARK)
            localStorage.theme = LIGHT
        }
    }, [isDark])

    const changeTheme = useCallback(() => {
        if (localStorage.theme !== DARK) {
            setIsDark(true)
        } else {
            setIsDark(false)
        }
        return null;
    }, [])

    const value = {
        isDark,
        changeTheme,
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}
