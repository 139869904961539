import dayjs from "dayjs";
import {useEffect, useState} from "react";

type Props = {
    checkInIsActive: boolean;
    setCheckInIsActive: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCheckInDate: Date | undefined;
    setSelectedCheckInDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    selectedCheckOutDate: Date | undefined;
    setSelectedCheckOutDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    numOfNights: number | undefined;
    setIsCalendarOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function CalendarView({checkInIsActive, setCheckInIsActive, selectedCheckInDate, setSelectedCheckInDate, selectedCheckOutDate, setSelectedCheckOutDate, numOfNights, setIsCalendarOpened}: Props) {
    const [currentDate, setCurrentDate] = useState(new Date());
    function handleCheckInButton() {
        setCheckInIsActive(true);
    }

    function handleCheckOutButton() {
        setCheckInIsActive(false);
    }

    function handleReset() {
        setSelectedCheckInDate(undefined);
        setSelectedCheckOutDate(undefined);
        setCurrentDate(new Date());
        setCheckInIsActive(true);
    }

    useEffect(() => {

    }, [checkInIsActive, selectedCheckInDate, selectedCheckOutDate, setCheckInIsActive, setSelectedCheckInDate, setSelectedCheckOutDate])

    return (
        <div className="flex flex-col gap-2 py-2 px-4 bg-neutral-50 dark:bg-neutral-800 border dark:border-opacity-30 dark:border-neutral-50 rounded shadow-xl dark:shadow-none">
            <div className="flex justify-between w-full">
                <div className="flex flex-col">
                    <div className="font-bold">Booking</div>
                    <span className="text-sm">{selectedCheckInDate && selectedCheckOutDate ? `${numOfNights} night${numOfNights && numOfNights > 1 ? "s" : ""} ` : "Choose booking dates"}</span>
                </div>
                <button onClick={handleReset} className="flex justify-end text-blue-400">
                    Reset
                </button>
                <div className="flex justify-evenly items-center bg-white dark:bg-neutral-800 border dark:border-opacity-30 dark:border-neutral-50 rounded h-fit w-fit">
                    <button onClick={handleCheckInButton} className={`flex flex-col ${checkInIsActive ? "dark:border-blue-500 border-blue-300" : "border-transparent"} border-2 rounded p-2 hover:bg-neutral-200 dark:hover:bg-neutral-700`}>
                        <h1 className="flex items-end text-xs font-extrabold ">CHECK-IN</h1>
                        <span>{selectedCheckInDate ? dayjs(selectedCheckInDate).format('YYYY-MM-DD') : "YYYY-MM-DD"}</span>
                    </button>
                    <button onClick={handleCheckOutButton} className={`flex flex-col ${!checkInIsActive ? "dark:border-blue-700 border-blue-500" : "border-transparent"} border-2 rounded p-2 hover:bg-neutral-200 dark:hover:bg-neutral-700`}>
                        <h1 className="flex items-end text-xs font-extrabold ">CHECK-OUT</h1>
                        <span>{selectedCheckOutDate ? dayjs(selectedCheckOutDate).format('YYYY-MM-DD') : "YYYY-MM-DD"}</span>
                    </button>
                </div>

            </div>
            <div className="flex gap-6 w-full ">
                <Calendar setCheckInIsActive={setCheckInIsActive} isOnLeft={true} currentViewDate={currentDate} setCurrentViewDate={setCurrentDate} selectedCheckInDate={selectedCheckInDate} selectedCheckOutDate={selectedCheckOutDate} setSelectedCheckInDate={setSelectedCheckInDate} setSelectedCheckOutDate={setSelectedCheckOutDate} checkInIsActive={checkInIsActive} />
                <Calendar setCheckInIsActive={setCheckInIsActive} isOnLeft={false} currentViewDate={currentDate} setCurrentViewDate={setCurrentDate} selectedCheckInDate={selectedCheckInDate} selectedCheckOutDate={selectedCheckOutDate} setSelectedCheckInDate={setSelectedCheckInDate} setSelectedCheckOutDate={setSelectedCheckOutDate} checkInIsActive={checkInIsActive} />
            </div>
            <div className="flex justify-end w-full ">
                <button onClick={() => setIsCalendarOpened(false)} className="dark:bg-blue-600 bg-blue-300 border border-neutral-50 border-opacity-30 rounded-lg p-1 px-4 mb-1">
                    Done
                </button>
            </div>
        </div>
    );
}

type CalendarProps = {
    isOnLeft: boolean;
    currentViewDate: Date;
    setCurrentViewDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedCheckInDate: Date | undefined;
    setSelectedCheckInDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    selectedCheckOutDate: Date | undefined;
    setSelectedCheckOutDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    checkInIsActive: boolean;
    setCheckInIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const Calendar = ({currentViewDate, isOnLeft, setCurrentViewDate, selectedCheckInDate, selectedCheckOutDate, setSelectedCheckInDate, setSelectedCheckOutDate, checkInIsActive, setCheckInIsActive}: CalendarProps) => {
    const [calendarDate, setCalendarDate] = useState(isOnLeft ? currentViewDate : new Date(currentViewDate.getFullYear(), currentViewDate.getMonth() + 1, 1));

    useEffect(() => {
        setCalendarDate(isOnLeft ? currentViewDate : new Date(currentViewDate.getFullYear(), currentViewDate.getMonth() + 1, 1))
    }, [currentViewDate, isOnLeft])


    const handleSelectDate = (day: number) => {
        let date = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), day);
        if (checkInIsActive) {
            setSelectedCheckInDate(date);
        }
        else {
            setSelectedCheckOutDate(date);
        }
        if (selectedCheckInDate || selectedCheckOutDate) {
            if (checkInIsActive) {
                if (selectedCheckOutDate !== undefined && date > selectedCheckOutDate) {
                    setSelectedCheckOutDate(undefined);
                }
            } else {
                if (selectedCheckInDate !== undefined && selectedCheckInDate > date) {
                    setSelectedCheckInDate(undefined);
                }
            }

        }
        setCheckInIsActive(!checkInIsActive);
    }

    const previousMonth = () => {
        setCurrentViewDate((prevDate) => {
            const prevMonth = prevDate.getMonth();
            const prevYear = prevDate.getFullYear();
            return new Date(prevYear, prevMonth - 1, 1);
        });
    };

    const nextMonth = () => {
        setCurrentViewDate((prevDate) => {
            const nextMonth = prevDate.getMonth() + 2; // Adding 2 to move to the next month
            const nextYear = prevDate.getFullYear();
            return new Date(nextYear, nextMonth - 1, 1);
        });
    };

    const generateCalendar = () => {
        let month = currentViewDate.getMonth();
        let year = currentViewDate.getFullYear();

        if (!isOnLeft) month++;
        if (!isOnLeft && month === 12) {
            month = 0;
            year++;
        }
        const numDays = new Date(year, month + 1, 0).getDate();
        const firstDayIndex = new Date(year, month, 1).getDay();
        const calendarCells = [];

        let day = 1;

        let selectedDay: number | undefined;
        let secondarySelectedDay: number | undefined;
        //check if day is selected
        if (selectedCheckInDate) {
            if (selectedCheckInDate.getMonth() === month && selectedCheckInDate.getFullYear() === currentViewDate.getFullYear()) {
                selectedDay = selectedCheckInDate.getDate();
            }
        }
        if (selectedCheckOutDate) {
            if (selectedCheckOutDate.getMonth() === month && selectedCheckOutDate.getFullYear() === currentViewDate.getFullYear()) {
                secondarySelectedDay = selectedCheckOutDate.getDate();
            }
        }



        // Generate calendar cells
        for (let i = 0; i < 6; i++) {
            const rowCells = [];

            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayIndex) {
                    // Empty cells before the first day of the month
                    rowCells.push(<td key={`empty-${j}`} />);
                } else if (day > numDays) {
                    // Empty cells after the last day of the month
                    break;
                } else {
                    const createButtonHandler = (date: number) => {
                        return () => handleSelectDate(date);
                    };
                    // Cells with dates
                    rowCells.push(<td key={`date-${day}`}
                        className="">
                        <button onClick={createButtonHandler(day)}
                            className={`w-8 h-8 hover:border hover:border-opacity-30 hover:border-neutral-50  rounded-full text-center select-none 
                            ${selectedDay === day && "dark:bg-blue-600 bg-blue-300 "}
                            ${secondarySelectedDay === day && "dark:bg-blue-800 bg-blue-500"}
                            ${(selectedDay !== day && secondarySelectedDay !== day) && "dark:hover:bg-neutral-700 hover:bg-neutral-200"} 
                            `}>
                            {day}
                        </button>
                    </td>);
                    day++;
                }
            }

            calendarCells.push(<tr key={`row-${i}`} >{rowCells}</tr>);
        }

        return calendarCells;
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div className="relative flex w-full justify-center items-center">
                {isOnLeft && <button className="absolute left-0 top-1" onClick={previousMonth}>
                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" >
                        <path d="M655-80 255-480l400-400 56 57-343 343 343 343-56 57Z" />
                    </svg>
                </button>}
                {isOnLeft ? <span>{calendarDate.toLocaleString("default", {month: "long"})} {calendarDate.getFullYear()}</span>
                    :
                    <span>{calendarDate.toLocaleString("default", {month: "long"})} {calendarDate.getFullYear()}</span>
                }
                {!isOnLeft && <button className="absolute right-0 top-1" onClick={nextMonth}>
                    <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                        <path d="m304-82-56-57 343-343-343-343 56-57 400 400L304-82Z" />
                    </svg>
                </button>}
            </div>
            <table>
                <thead>
                    <tr>
                        <th className="text-sm">Sun</th>
                        <th className="text-sm">Mon</th>
                        <th className="text-sm">Tue</th>
                        <th className="text-sm">Wed</th>
                        <th className="text-sm">Thu</th>
                        <th className="text-sm">Fri</th>
                        <th className="text-sm">Sat</th>
                    </tr>
                </thead>
                <tbody>{generateCalendar()}</tbody>
            </table>
        </div>
    )
}
