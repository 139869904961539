import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

type Props = {
    modal: ReactNode
    isShowing: boolean
}

export const Modal = ({ modal, isShowing }: Props) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        {modal}
    </React.Fragment>, document.body
) : null;