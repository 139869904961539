const formatTime = (time: number) => {
    let milliseconds = time % 1000;
    time = Math.floor(time / 1000);
    let seconds = time % 60;
    time = Math.floor(time / 60);
    let minutes = time % 60;
    time = Math.floor(time / 60);
    let hours = time % 60;
    time = Math.floor(time / 24);
    let days = time
    return { days, hours, minutes, seconds, milliseconds }
}

const lapDisplayFormat = (time: number, format: string) => {
    let { days, hours, minutes, seconds, milliseconds } = formatTime(time)
    let s = (seconds < 10) ? '0' + seconds : seconds.toString()
    let ms = (milliseconds < 10) ? '0' + milliseconds : milliseconds.toString().substring(0, 2)
    let main = ""
    if (format === "days") {
        main = days.toString() + " "
        main += (hours < 10) ? '0' + hours + " " : hours.toString() + " "
        main += (minutes < 10) ? '0' + minutes + " " : minutes.toString() + " "
        main += s + "."
    }
    else if (format === "doubleHours") {
        main += (hours < 10) ? '0' + hours + " " : hours.toString() + " "
        main += (minutes < 10) ? '0' + minutes + " " : minutes.toString() + " "
        main += s + "."
    } else if (format === "singleHours") {
        main += hours.toString() + " "
        main += (minutes < 10) ? '0' + minutes + " " : minutes.toString() + " "
        main += s + "."
    }
    else if (format === "doubleMinutes") {
        main += (minutes < 10) ? '0' + minutes + " " : minutes.toString() + " "
        main += s + "."
    } else {
        main += minutes.toString() + " "
        main += s + "."
    }
    return main += ms
}



export { formatTime, lapDisplayFormat }