import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";

type Props = {
    requiredRole?: string
}
export const RequireAuth = ({requiredRole}: Props) => {
    let {currentUser, emailVerified} = useAuth();

    let location = useLocation();
    let pathname = location.pathname
    if (currentUser === null || currentUser === undefined) {
        return <Navigate to="/login" state={{from: pathname}} replace />
    }

    //user can sign out on this page
    if (!emailVerified) {
        if (pathname !== "/verification") {
            return <Navigate to="/verification" state={{from: pathname}} replace />
        }
    }

    if (emailVerified && pathname === "/verification") {
        return <Navigate to="/" state={{from: pathname}} replace />
    }

    return <Outlet />;
}
