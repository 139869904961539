import {createContext, useContext, useEffect, useState} from "react";
import {useAuth} from "./AuthContext"
import {UserData} from "../shared/types"
import {db} from "../firebase"
import {onSnapshot, doc, setDoc} from "firebase/firestore";

type User = {
  editProfile: (obj: UserData) => Promise<string>
  displayName: string
  avatar: string
  avatarColor: string
}

const UserContext = createContext<User>({
  editProfile: (obj: UserData) => new Promise(() => ""),
  displayName: "",
  avatar: "",
  avatarColor: ""
})

export const useUser = () => useContext(UserContext)

export const UserProvider = ({children}: {children: React.ReactNode}) => {
  const {currentUser} = useAuth()
  const [displayName, setDisplayName] = useState("")
  const [avatar, setAvatar] = useState("")
  const [avatarColor, setAvatarColor] = useState("white")
  const [loading, setLoading] = useState(true)
  const USER_SUCCESS = "success"
  const USER_FAILURE = "failure"

  useEffect(() => {
    let unsub: () => void
    if (currentUser !== null && currentUser !== undefined) {
      unsub = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
        let userHolder = doc.data()
        if (userHolder === undefined) {
        } else {
          setAvatarColor(userHolder?.avatarColor)
          setAvatar(userHolder?.avatar)
          setDisplayName(userHolder?.displayName)
          setLoading(false)
        }
      });
    } else {
      if (currentUser === null) {
        setLoading(false)
      }
    }
    return () => {
      if (unsub) unsub()
    }
  }, [currentUser])

  const editProfile = async (profile: UserData) => {
    if (!currentUser) return ""
    try {
      await setDoc(doc(db, 'users', currentUser.uid), {
        displayName: profile.displayName,
        avatar: profile.avatar,
        avatarColor: profile.avatarColor
      })
      setAvatarColor(profile.avatarColor)
      setAvatar(profile.avatar)
      setDisplayName(profile.displayName)
      return USER_SUCCESS
    } catch (e) {
      return USER_FAILURE
    }
  }

  const value = {
    editProfile,
    displayName,
    avatar,
    avatarColor,
  }

  //? <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-blue-300 dark:bg-gray-800 animate-pulse"></div>
  return (
    <UserContext.Provider value={value}>
      {!loading && children}
    </UserContext.Provider>
  )
}


