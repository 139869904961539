import { GoogleSigninSVG } from "../../common/svg/GoogleSigninSVG"

type Props = {
    loading: boolean
    googleClick: () => void
}

export const GoogleButton = (({ loading, googleClick }: Props) => {
    return (
        <button disabled={loading} onClick={googleClick} className="relative border w-full p-2 rounded-md dark:border-black dark:border-opacity-25 dark:bg-blue-googleBlue">
            <div className=" absolute top-0 left-0"> <GoogleSigninSVG className="h-10 w-10" /></div>
            <div className="flex justify-center align-middle">
                <p>Sign in with Google</p>
            </div>
        </button>
    )
})