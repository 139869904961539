import { useState } from "react"
import { AUTH_SUCCESS, useAuth } from "../../contexts/AuthContext"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ErrorSVG } from "../../common/svg/ErrorSVG";
import { Input } from "../../common/styled/Input";
import { SubmitButton } from "../../common/styled/SubmitButton";
import { GoogleButton } from "./GoogleButton";
import { Form } from "../../common/styled/Form";
import { Divider } from "../../common/styled/Divider";
import { PageCard } from "../../common/styled/decorators/PageCard";

export const Login = () => {
    let navigate = useNavigate();
    let location: any = useLocation();
    let from = location.state?.from || "/";

    const { signIn, googleLogin } = useAuth()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (email.length === 0 || password.length === 0) {
            setError("Please fill in all fields.")
            return
        }
        setLoading(true)
        const result = await signIn(email, password);
        if (result === AUTH_SUCCESS) {
            navigate(from, { replace: true });
        } else {
            setError(result)
            setLoading(false)
        }
    }

    const googleClick = async () => {
        setLoading(true)
        const result = await googleLogin()
        if (result === AUTH_SUCCESS) {
            navigate(from, { replace: true });
        } else {
            setLoading(false)
        }
    }

    return (
        <PageCard children={<>
            <h1 className="font-bold text-xl w-full">My Company Hill Account</h1>
            <div className="flex justify-start w-full">
                <p> Don't have one? Create one </p>
                <Link to="/signup" state={{ from: from }} replace className="text-blue-600 ml-1">here!</Link>
            </div>

            <Form onSubmit={handleSubmit} children={<>
                <div className="w-full">
                    <label>Email</label>
                    <Input setState={setEmail} state={email} type="email" placeholder="Email" />
                </div>
                <div className="w-full">
                    <label>Password</label>
                    <Input setState={setPassword} state={password} type="password" placeholder="Password" />
                </div>
                <div className="flex justify-between w-full">
                    {error.length > 0 &&
                        <div className="flex justify-start w-full">
                            <ErrorSVG />
                            <div className="ml-1">{error}</div>
                        </div>
                    }
                    <p className="w-full text-right text-base">Forgot your
                        <Link to="/recovery" state={{ from: from }} replace className="text-blue-600 ml-2 text-base">password?</Link>
                    </p>
                </div>
                <SubmitButton disabled={loading} text="Log In" />
            </>} />
            <Divider text="or" />
            <GoogleButton loading={loading} googleClick={googleClick} />
        </>} />
    )
}